import { ProtectedResourceScopes } from '@azure/msal-angular';

interface MsalTokenEndpointConfig {
  endpoint: string;
  methods: Array<'POST' | 'GET' | 'DELETE' | 'PATCH' | 'PUT'>;
}

export const composeMsalEndpointsConfig = (
  endpoints: Array<MsalTokenEndpointConfig>,
  tokenScope: string,
): Map<string, Array<ProtectedResourceScopes>> => {
  const mapped = endpoints.map(({ endpoint, methods }) => [
    endpoint,
    methods.map((method) => ({
      httpMethod: method,
      scopes: [tokenScope],
    })),
  ]);

  return new Map([...(mapped as any)]);
};
