import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';

import { DeviceService } from '@aw/prypco/services/device';
import { SnackBarService } from '@aw/prypco/services/snackbar';

import { VersionEvent } from './enum/prompt-update-service.enum';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class PromptUpdateService {
  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly swUpdate: SwUpdate,
    private readonly snackBarService: SnackBarService,
    private readonly deviceService: DeviceService,
  ) {}

  init(): void {
    /**
     * Disable the update service for mobile apps since it opens the website in a popup
     * TODO: Implement in a differnet way for mobile apps;
     */
    if (this.deviceService.isApp) {
      return;
    }

    this.swUpdate.versionUpdates
      .pipe(
        filter(
          (versionEvent) => versionEvent.type === VersionEvent.VersionReady,
        ),
        untilDestroyed(this),
      )
      .subscribe({
        next: ({ type }) => {
          const message = `❗Version event: ${type}`;

          console.log(`%c ${message}`, 'color: #D40141');

          this.snackBarService.info(
            'A new version of the application is being installed',
            'Application Update',
          );

          // Reload the page to update to the latest version.
          setTimeout(() => {
            this.document.location.reload();
          }, 8000);
        },
      });
  }
}
