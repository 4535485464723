import { NavSegments } from '@aw/shared/models';

export const composeHref = (...segments: NavSegments): string => {
  let url = `${document.location.protocol}//${document.location.host}`;

  segments.forEach((segment) => {
    url = `${url}/${segment}`;
  });

  return url;
};
