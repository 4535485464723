export enum UserIdentifier {
  Calendly = 'CALENDLY',
  ZiwoId = 'ZIWO_ID',
}

export enum UserIdentifierType {
  CalendlyUserName = 'CALENDLY_USER_NAME',
  ZiwoId = 'ZIWO_ID',
}

export enum UserIdentifierSource {
  Internal = 'INTERNAL',
  Ziwo = 'ZIWO',
}
