import { Country } from '@aw/shared/enums';
import { Money, uuid } from '@aw/shared/models';

import { Lead } from '@aw/prypco/models';
import {
  EmploymentIndustry,
  WealthSource,
} from '@aw/prypco/services/reference-data';

import { EmploymentStatus } from '../../enums/block-service.enum';

export interface BlocksLead extends Lead {
  id: uuid;
  countryOfCitizenship: Country | null;
  countryOfResidence: Country | null;
  wealthSource: WealthSource | null;
  plannedInvestment: Money | null;
  employment: BlocksLeadEmployment | null;
  steps: BlocksOnboardingSteps;

  // TODO: Replace with the actual statuses
  status: string;
  stage: string | null;
}

export interface BlocksLeadEmployment {
  employmentStatus: EmploymentStatus;
  monthlyIncome: Money;
  employmentIndustry: EmploymentIndustry | null;
  countryOfEmployment: Country | null;
  employerName: string | null;
}

export interface BlocksOnboardingSteps {
  profile: boolean;
  employmentStatus: boolean;
  investmentPlanning: boolean;
  uploadDocuments: boolean;
  addressVerification: boolean;
}

export enum BlocksRedoOnboardingSteps {
  Profile = 'profile',
  Employment = 'employmentStatus',
  InvestmentPlanning = 'investmentPlanning',
  Address = 'addressVerification',
  DocumentUpload = 'uploadDocuments',
}

export type BlocksOnboardingStepModel = {
  [key in BlocksRedoOnboardingSteps]: {
    completed: boolean;
    disabled: boolean;
  };
};
