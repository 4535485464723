export class ExternalScript {
  declaration?: string;

  get loaded(): boolean {
    return this._loaded;
  }

  constructor(
    readonly name = '',
    readonly src = '',
    private _loaded = false,
    readonly enabled = false,
  ) {}

  setLoaded(loaded: boolean): void {
    this._loaded = loaded;
  }
}

export class AmplitudeScript extends ExternalScript {
  constructor(
    readonly apiKey: string,
    readonly options: {
      defaultTracking: {
        attribution: boolean;
        pageViews: boolean;
        sessions: boolean;
        formInteractions: boolean;
        fileDownloads: boolean;
      };
      optOut: boolean;
    },
    readonly debug: boolean,
    private readonly _enabled: boolean,
  ) {
    super('Amplitude', undefined, false, _enabled);
  }
}

export class GoogleTagManagerScript extends ExternalScript {
  constructor(
    private readonly id: string,
    private readonly _enabled: boolean,
  ) {
    super(
      'Google Tag Manager',
      `https://www.googletagmanager.com/gtm.js?id=${id}`,
      false,
      _enabled,
    );
  }
}

export class HotjarScript extends ExternalScript {
  constructor(
    readonly hjid: number,
    readonly hjsv: number,
    private readonly _enabled: boolean,
  ) {
    super('Hotjar', undefined, false, _enabled);
  }
}

export class LeanScript extends ExternalScript {
  constructor(
    readonly apiKey: string,
    readonly isSandbox: boolean,
    readonly permissions = [
      'identity',
      'accounts',
      'transactions',
      'balance',
      'payments',
    ],
  ) {
    super(
      'Lean',
      'https://cdn.leantech.me/link/loader/prod/ae/latest/lean-link-loader.min.js',
      false,
      true,
    );
  }
}
