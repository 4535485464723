import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  UrlTree,
} from '@angular/router';
import { get } from 'lodash-es';
import { Observable, first, map } from 'rxjs';

import { JobTitle, Module } from '@aw/prypco/enums';
import { AuthFacade } from '@aw/prypco/state/auth';

import { RoleGuardData } from './enums/enums';

@Injectable({ providedIn: 'root' })
export class RoleGuard implements CanLoad, CanActivate {
  constructor(
    private readonly authFacade: AuthFacade,
    private readonly router: Router,
  ) {}

  private redirectAttempts = 0;

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.common(route);
  }

  canLoad(route: Route): Observable<boolean | UrlTree> {
    return this.common(route);
  }

  private common(
    route: Route | ActivatedRouteSnapshot,
  ): Observable<boolean | UrlTree> {
    const roles = this.getRolesFromRouteConfig(route);

    return this.authFacade.jobTitle$.pipe(
      first(),
      map((jobTitle) => {
        const isMatching = roles.includes(jobTitle);

        if (isMatching) {
          return true;
        }

        const redirectUrl = this.getRedirectUrl(jobTitle);

        if (redirectUrl) {
          return redirectUrl;
        }

        this.redirectAttempts++;

        if (this.redirectAttempts === 1) {
          // TODO: Investigate
          console.error(
            'Attempted route access with an unknown role. Please provide the role in the routing module defining the route. Bailing to Mortgage Dashboard',
          );

          return this.router.createUrlTree([
            `/${Module.Mortgage}`,
            Module.Dashboard,
          ]);
        }
        console.error(
          'Maximum attempts to unknown redirects reached. Bailing to landing page',
        );

        return this.router.createUrlTree(['/']);
      }),
    );
  }

  private getRedirectUrl(jobTitle: JobTitle): UrlTree | undefined {
    const routes = this.router.config;

    for (const route of routes) {
      const roles = this.getRolesFromRouteConfig(route);

      if (roles.includes(jobTitle)) {
        return this.router.createUrlTree([route.path as string]);
      }
    }

    return undefined;
  }

  private readonly getRolesFromRouteConfig = (
    config: Route | ActivatedRouteSnapshot,
  ): Array<JobTitle> => get(config, ['data', RoleGuardData.Roles], []);
}
