import { OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { Subject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { SnackBarConfig } from './snackbar-config.model';

export class SnackBarRef {
  readonly id: string;

  get dimensions(): DOMRect {
    return this.overlay.overlayElement.getBoundingClientRect();
  }

  /**
   * Emits only once in order to trigger the closure of the notification
   * Note that this doesn't mean that the notification and the overlay
   * are immediately destroyed, as they wait for the animation to finish
   */
  readonly close$ = new Subject<string>();

  /**
   * Emits once when the overlay is destroyed
   */
  readonly destroyed$ = new Subject<string>();

  constructor(
    private readonly overlay: OverlayRef,
    readonly config: SnackBarConfig,
  ) {
    this.id = uuidv4();
  }

  close(): void {
    this.close$.next(this.id);
    this.close$.complete();
  }

  destroy(): void {
    this.destroyed$.next(this.id);
    this.destroyed$.complete();

    this.overlay.dispose();
  }

  updatePosition(strategy: PositionStrategy): void {
    this.overlay.updatePositionStrategy(strategy);
    this.overlay.updatePosition();
  }
}
