import {
  blocksBackOfficeRoles,
  listingBackOfficeRoles,
  mortgageBackOfficeRoles,
  servicesBackOfficeRoles,
} from '@aw/prypco/constants';
import { JobTitle } from '@aw/prypco/enums';

export const canViewRewards = (
  jobTitle?: JobTitle,
  isInvestor?: boolean,
): boolean => !!(jobTitle === JobTitle.Member && isInvestor);

export const isMortgageBackOfficeUser = (jobTitle?: JobTitle): boolean =>
  jobTitle ? mortgageBackOfficeRoles.includes(jobTitle) : false;

export const isBlocksBackOfficeUser = (jobTitle?: JobTitle): boolean =>
  jobTitle ? blocksBackOfficeRoles.includes(jobTitle) : false;

export const isListingBackOfficeUser = (jobTitle?: JobTitle): boolean =>
  jobTitle ? listingBackOfficeRoles.includes(jobTitle) : false;

export const isServicesBackOfficeUser = (jobTitle?: JobTitle): boolean =>
  jobTitle ? servicesBackOfficeRoles.includes(jobTitle) : false;

export const canViewMyTeam = (jobTitle?: JobTitle): boolean =>
  jobTitle === JobTitle.SysAdmin ||
  jobTitle === JobTitle.SuperMortgageAdvisorManager ||
  jobTitle === JobTitle.MortgageAdvisorManagerAgency ||
  jobTitle === JobTitle.MortgageAdvisorManagerBroker;

export const canViewAgencies = (jobTitle?: JobTitle): boolean =>
  jobTitle
    ? jobTitle === JobTitle.SysAdmin ||
      jobTitle === JobTitle.SuperMortgageAdvisorManager ||
      jobTitle === JobTitle.MortgageAdvisorManagerAgency ||
      jobTitle === JobTitle.MortgageAdvisor ||
      jobTitle === JobTitle.Telesales
    : false;

export const canViewBrokerages = (jobTitle?: JobTitle): boolean =>
  jobTitle
    ? jobTitle === JobTitle.SysAdmin ||
      jobTitle === JobTitle.SuperMortgageAdvisorManager ||
      jobTitle === JobTitle.MortgageAdvisorManagerBroker ||
      jobTitle === JobTitle.MortgageAdvisor
    : false;

export const canViewMortgageBackOffice = (jobTitle?: JobTitle): boolean =>
  isMortgageBackOfficeUser(jobTitle);

export const canViewListingBackOffice = (jobTitle?: JobTitle): boolean =>
  isListingBackOfficeUser(jobTitle);

export const canViewBlocksBackOffice = (jobTitle?: JobTitle): boolean =>
  isBlocksBackOfficeUser(jobTitle);

export const canViewServicesBackOffice = (jobTitle?: JobTitle): boolean =>
  isServicesBackOfficeUser(jobTitle);

export const canViewBackOffice = (jobTitle?: JobTitle): boolean =>
  jobTitle
    ? canViewMortgageBackOffice(jobTitle) ||
      canViewListingBackOffice(jobTitle) ||
      canViewBlocksBackOffice(jobTitle)
    : false;
