export enum ReferenceDataCatalogue {
  EmploymentSituation = 'EMPLOYMENT_SITUATION',
  Labels = 'LABELS',
  Text = 'TEXT',
  LeadPreferredLanguage = 'LEAD_PREFERRED_LANGUAGE',
  Essential = 'ESSENTIAL',
  MortgageAdditionalPersonalDocCategory = 'MORTGAGE_APPLICATION_ADDITIONAL_DOC_TYPE',
  Placeholders = 'PLACEHOLDERS',
  FormError = 'FORM_ERROR',
  MortgageAdditionalPropertyDocCategory = 'MORTGAGE_ADDITIONAL_PROPERTY_DOC_CATEGORY',
  MortgageOfferBank = 'MORTGAGE_OFFER_BANK',
  CreditCardBank = 'CREDIT_CARD_BANK',
  MortgageOfferInterestRate = 'MORTGAGE_OFFER_INTEREST_RATE',
  TableHeader = 'TABLE_HEADER',
  ServicesProductType = 'SERVICES_PRODUCT_TYPE',

  Error = 'ERROR',
  Success = 'SUCCESS',

  Role = 'ROLE',
  Accessibility = 'ACCESSIBILITY',
  CalendlyEventType = 'CALENDLY_EVENT_TYPE',
  Currency = 'CURRENCY',
  Country = 'COUNTRY_CODE',
  FormLabel = 'FORM_LABEL',
  BusinessUnit = 'BUSINESS_UNIT',

  MortgageApplicationStatus = 'APPLICATION_STATUS',
  MortgageApplicationStage = 'APPLICATION_STAGE',
  MortgageApplicationStatusStage = 'APPLICATION_STATUS_STAGE',
  LiabilityLoanType = 'LIABILITY_LOAN_TYPE',
  MortgageLoanType = 'MORTGAGE_LOAN_TYPE',
  MortgageTransactionType = 'MORTGAGE_TRANSACTION_TYPE',

  MortgageLeadStatus = 'LEAD_STATUS',
  MortgageLeadStage = 'LEAD_STAGE',
  LeadStatusManualTransition = 'LEAD_STATUS_MANUAL_TRANSITION',
  LeadStatusStage = 'LEAD_STATUS_STAGE',
  LeadChannel = 'LEAD_CHANNEL',
  UaeResidencyStatus = 'UAE_RESIDENCY_STATUS',
  HousingStatus = 'HOUSING_STATUS',

  // TODO: Adrian Dima: Add Services reference data dependencies
  BlocksPropertyType = 'BLOCKS_PROPERTY_TYPE',
  PropertyStatus = 'PROPERTY_STATUS',
  PropertyRoomType = 'PROPERTY_ROOM_TYPE',
  PropertyAmenity = 'PROPERTY_AMENITY',
  BlocksPropertyDocumentType = 'BLOCKS_PROPERTY_DOCUMENT_TYPE',
  BlocksAddressDocument = 'BLOCKS_ADDRESS_DOCUMENT',
  BlocksUserStatus = 'BLOCKS_USER_STATUS',
  BlocksMemberRewardType = 'BLOCKS_MEMBER_REWARD_TYPE',
  BlocksMemberRewardSource = 'BLOCKS_MEMBER_REWARD_SOURCE',

  CustomerDocument = 'CUSTOMER_DOCUMENT',

  ListingPropertyType = 'LISTING_PROPERTY_TYPE',
  ListingPropertyDocumentType = 'LISTING_PROPERTY_DOCUMENT_TYPE',
  ListingPropertyDevelopmentStage = 'LISTING_PROPERTY_DEVELOPMENT_STAGE',
  LandlordDocumentType = 'LANDLORD_DOCUMENT_TYPE',

  TransactionStatus = 'TRANSACTION_STATUS',
  TransactionType = 'TRANSACTION_TYPE',
  TransactionInstrument = 'TRANSACTION_INSTRUMENT',
  TransactionCancellationReason = 'TRANSACTION_CANCELLATION_REASON',
  TransactionWithdrawCancellationReason = 'TRANSACTION_WITHDRAWAL_CANCELLATION_REASON',
  TransactionWithdrawCancellationReason2 = 'TRANSACTION_WITHDRAWAL_CANCELLATION_REASON_2',
  PaymentGatewayResponseName = 'PAYMENT_GATEWAY_RESPONSE_NAME',

  EmploymentIndustry = 'EMPLOYMENT_INDUSTRY',
  FocalEmployerIndustry = 'FOCAL_EMPLOYER_INDUSTRY',
  BlocksEmploymentStatus = 'BLOCKS_EMPLOYMENT_STATUS',
  BlocksInvestment = 'BLOCKS_INVESTMENT',
  BlocksRentalType = 'BLOCKS_RENTAL_TYPE',
  BlocksPropertyFurnishingsType = 'BLOCKS_PROPERTY_FURNISHINGS_TYPE',
  BlocksVacancyType = 'BLOCKS_VACANCY_STATUS',
  WealthSource = 'WEALTH_SOURCE',
  FocalWealthSource = 'FOCAL_WEALTH_SOURCE',
  MaritalStatus = 'MARITAL_STATUS',
  PropertyArea = 'PROPERTY_AREA',

  InterestType = 'INTEREST_TYPE',
  EiborType = 'EIBOR_TYPE',

  Calendar = 'CALENDAR',

  NotificationType = 'NOTIFICATION_TYPE',
  NotificationTitle = 'NOTIFICATION_TITLE',
  NotificationMessage = 'NOTIFICATION_MESSAGE',

  BlocksPaymentMethod = 'BLOCKS_PAYMENT_METHOD',

  InvestmentPropertyUpfrontFee = 'INVESTMENT_PROPERTY_UPFRONT_FEE_TYPE',
  RewardsTier = 'REWARDS_TIER',
  BlocksInvestorRejectionReasons = 'BLOCKS_INVESTOR_REJECTION_REASONS',
  InsuranceType = 'INSURANCE_TYPE',
}

export enum EmploymentIndustry {
  AirTransportation = '481',
  PostalServices = '491',
}

export enum WealthSource {
  Salary = 'SALARY',
  Investment = 'INVESTMENT',
  Inheritance = 'INHERITANCE',
  Winnings = 'WINNINGS',
}
