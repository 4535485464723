import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { NgClass } from '@angular/common';
import { NgModule } from '@angular/core';

import { DialogContainerComponent } from './components/dialog-container/dialog-container.component';
import { DialogService } from './dialog.service';
import { DialogConfig } from './models/dialog-config.model';
import { DIALOG_CONFIG } from './tokens/dialog-config.token';

@NgModule({
  declarations: [DialogContainerComponent],
  imports: [NgClass, A11yModule, PortalModule, OverlayModule],
  providers: [
    DialogService,
    { provide: DIALOG_CONFIG, useValue: new DialogConfig() },
  ],
})
export class DialogServiceModule {}
