import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as SplashScreenActions from './splash-screen.actions';
import * as SplashScreenSelectors from './splash-screen.selectors';

@Injectable()
export class SplashScreenFacade {
  readonly loading$: Observable<boolean> = this.store.select(
    SplashScreenSelectors.getIsLoading,
  );

  constructor(private readonly store: Store) {}

  setLoading(key: string, isLoading: boolean): void {
    this.store.dispatch(SplashScreenActions.setLoading({ key, isLoading }));
  }
}
