import { isNil, pickBy } from 'lodash-es';

import { nil } from '@aw/shared/models';

import { LeadMetadata } from '@aw/prypco/models';
import { UtmTag } from '@aw/prypco/services/utm';

export const utmToMetadata = (
  utm: Partial<Record<UtmTag, string>> | nil,
): Partial<LeadMetadata> | undefined => {
  if (isNil(utm)) {
    return undefined;
  }

  return pickBy({
    platform: utm['platform'],
    utmSource: utm['utm_source'],
    utmMedium: utm['utm_medium'],
    utmCampaign: utm['utm_campaign'],
    utmId: utm['utm_id'],
    utmTerm: utm['utm_term'],
    utmContent: utm['utm_content'],
    referral: utm['referral'],
  });
};
