export abstract class BlobServiceBase {
  abstract download(fileName: string, blob: Blob): void;

  abstract downloadWithUrl(url: string): void;

  readonly renameFile = (file: File, newName: string): File => {
    const extension = this.getFileExtension(file.name);

    const nameWithExtension = this.hasExtension(newName)
      ? newName
      : `${newName}.${extension}`;

    return new File([file], nameWithExtension, {
      type: file.type,
      lastModified: file.lastModified,
    });
  };

  readonly getFileExtension = (name: string): string => {
    if (this.hasExtension(name)) {
      const split = name.split('.');

      return split.pop() as string;
    }

    return '';
  };

  private readonly hasExtension = (name: string): boolean => name.includes('.');
}
