<div
  class="pointer-events-none fixed inset-0 flex items-start p-6"
  aria-live="assertive"
  [@snackbar]="animationState"
  (@snackbar.done)="onAnimationDone($event)"
>
  <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
    <div
      class="pointer-events-auto w-full max-w-sm overflow-hidden shadow-lg ring-1 ring-black ring-opacity-5"
    >
      <ng-content />
    </div>
  </div>
</div>
