import { Pipe, PipeTransform } from '@angular/core';

import { _RequestStatus, isDomainLoading } from './domain-entity.util';

@Pipe({
  standalone: true,
  name: 'awIsDomainLoading',
})
export class IsDomainLoadingPipe implements PipeTransform {
  transform(entity: { status: _RequestStatus; [key: string]: any }): boolean {
    return isDomainLoading(entity);
  }
}
