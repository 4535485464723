import { SvgIconName } from '@aw/shared/components/icon';

import { Amenity, RoomType } from '@aw/prypco/enums';

export const amenityIcon: Record<Amenity, SvgIconName> = {
  [Amenity.Gym]: 'gym',
  [Amenity.Pool]: 'swim',
  [Amenity.Balcony]: 'balcony',
  [Amenity.OpenPlanKitchen]: 'blender',
  [Amenity.BillIncluded]: 'receipt_long',
  [Amenity.CoveredParking]: 'garage_home',
  [Amenity.PlayingArea]: 'person_play',
  [Amenity.PartyHall]: 'celebration',
  [Amenity.Concierge247]: 'local_convenience_store',
  [Amenity.Jacuzzi]: 'hot_tub',
  [Amenity.KitchenAppliance]: 'blender',
  [Amenity.BbqArea]: 'outdoor_grill',
  [Amenity.LandscapeGarden]: 'nature',
  [Amenity.Security]: 'admin_panel_settings',
  [Amenity.AirConditioning]: 'ac_unit',
  [Amenity.BuildInWardrobe]: 'wardrobe',
  [Amenity.CommunityView]: 'curtains',
  [Amenity.BurjKhalifaView]: 'burj_khalifa',
  [Amenity.GolfCourseView]: 'golf_course',
  [Amenity.CanalView]: 'canal_view',
  [Amenity.NearMetro]: 'subway',
  [Amenity.NearSchool]: 'school',
  [Amenity.NearMall]: 'local_mall',
  [Amenity.NearHospital]: 'home_health',
  [Amenity.OnHighFloor]: 'high_floor',
  [Amenity.OnMidFloor]: 'mid_floor',
  [Amenity.OnLowFloor]: 'low_floor',
  [Amenity.PetsAllowed]: 'pets',
  [Amenity.SwimmingPool]: 'pool',
  [Amenity.StudyRoom]: 'auto_stories',
  [Amenity.DriversRoom]: 'auto_meeting_room',
  [Amenity.MaidsRoom]: 'face_4',
  [Amenity.CommunityCenter]: 'diversity',
  [Amenity.HealthClub]: 'sports-gymnastics',
  [Amenity.Supermarket]: 'store',
  [Amenity.Restaurant]: 'restaurant',
  [Amenity.Spa]: 'spa',
};

export const propertyRoomIcon: Record<RoomType, SvgIconName> = {
  [RoomType.Bathroom]: 'bathroom',
  [RoomType.Bedroom]: 'bed',
  [RoomType.Kitchen]: 'kitchen',
  [RoomType.LivingRoom]: 'sofa',
  [RoomType.MaidRoom]: 'face_4',
  [RoomType.DriversRoom]: 'auto_meeting_room',
  [RoomType.Office]: 'office',
};
