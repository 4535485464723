import { AnimationEvent } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { first } from 'rxjs';

import { snackbarAnimation } from '../../animations/snackbar.animation';
import { SnackBarRef } from '../../models/snackbar-ref.model';
import { SNACKBAR_REF } from '../../tokens/snackbar-ref.token';
import { SnackBarAnimationState } from '../../types/type';

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'po-snackbar-container',
  templateUrl: 'snackbar-container.component.html',
  animations: [snackbarAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnackBarContainerComponent {
  animationState: SnackBarAnimationState = 'open';

  constructor(
    @Inject(SNACKBAR_REF) readonly ref: SnackBarRef,
    private readonly changeDetector: ChangeDetectorRef,
  ) {
    this.handleClosure();
  }

  onAnimationDone(event: AnimationEvent): void {
    const toState = event.toState as SnackBarAnimationState;

    if (toState === 'close') {
      this.ref.destroy();
    }
  }

  /**
   * The Notification Service will close this notification after a set amount
   * of time, unless it's marked as permanent
   */
  private handleClosure(): void {
    this.ref.close$.pipe(first(), untilDestroyed(this)).subscribe(() => {
      this.animationState = 'close';

      this.changeDetector.markForCheck();
    });
  }
}
