import {
  ComponentType,
  Overlay,
  OverlayConfig,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  Inject,
  Injectable,
  Injector,
  Optional,
  StaticProvider,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, take } from 'rxjs';

import { EventFacade, GlobalEventType } from '@aw/prypco/state/events';

import { DialogRef } from './abstracts/dialog-ref';
import { DialogContainerComponent } from './components/dialog-container/dialog-container.component';
import { DialogConfig } from './models/dialog-config.model';
import { DIALOG_CONFIG } from './tokens/dialog-config.token';
import { DIALOG_DATA } from './tokens/dialog-data.token';

// TODO: Dialog stack, close all etc.
@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class DialogService {
  constructor(
    @Optional() private readonly overlay: Overlay,
    private readonly injector: Injector,
    @Optional()
    private readonly eventFacade: EventFacade,
    @Optional()
    @Inject(DIALOG_CONFIG)
    private readonly _defaultDialogConfig?: DialogConfig,
  ) {
    if (this.eventFacade) {
      this.initEventHandler();
    }
  }

  private navsHiddenByService = false;

  private navsAlreadyHidden = false;

  private fabsHiddenByService = false;

  private fabsAlreadyHidden = false;

  private get defaultDialogConfig(): DialogConfig {
    return this._defaultDialogConfig || new DialogConfig();
  }

  open<T, R = any>(
    component: ComponentType<any>,
    dialogData?: any,
    config?: Partial<DialogConfig>,
  ): DialogRef<T, R> {
    const dialogConfig = { ...this.defaultDialogConfig, ...(config || {}) };

    const overlay = this.composeOverlay(dialogConfig);
    const dialogContainer = this.attachDialogContainer(overlay, dialogConfig);

    const dialogRef = this.attachDialog<T, R>(
      component,
      dialogContainer,
      overlay,
      dialogConfig,
      dialogData,
    );

    if (!this.navsAlreadyHidden) {
      this.hideNavigationBars();
    }

    if (!this.fabsAlreadyHidden) {
      this.hideFabs();
    }

    dialogRef.closed.pipe(take(1)).subscribe({
      next: () => {
        if (this.navsHiddenByService) {
          this.navsHiddenByService = false;
          this.showNavigationBars();
        }

        if (this.fabsHiddenByService) {
          this.fabsHiddenByService = false;
          this.showFabs();
        }
      },
    });

    return dialogRef;
  }

  openActionDialog<T, R = any>(
    component: ComponentType<any>,
    dialogData?: any,
    config?: Partial<DialogConfig>,
  ): DialogRef<T, R> {
    return this.open(component, dialogData, { ...config, type: 'action' });
  }

  private initEventHandler(): void {
    this.eventFacade.event$
      .pipe(
        untilDestroyed(this),
        filter(
          ({ event }) =>
            event === GlobalEventType.HideTopNav ||
            event === GlobalEventType.ShowTopNav ||
            event === GlobalEventType.HideFabs ||
            event === GlobalEventType.ShowFabs,
        ),
      )
      .subscribe({
        next: ({ event }) => {
          if (
            event === GlobalEventType.HideTopNav &&
            !this.navsHiddenByService
          ) {
            this.navsAlreadyHidden = true;
          } else if (event === GlobalEventType.ShowBottomNav) {
            this.navsAlreadyHidden = false;
            this.navsHiddenByService = false;
          }

          if (event === GlobalEventType.HideFabs && !this.fabsHiddenByService) {
            this.fabsAlreadyHidden = true;
          } else if (event === GlobalEventType.ShowFabs) {
            this.fabsAlreadyHidden = false;
            this.fabsHiddenByService = false;
          }
        },
      });
  }

  private attachDialog<T, R>(
    component: ComponentType<T>,
    dialogContainer: DialogContainerComponent,
    overlayRef: OverlayRef,
    config: DialogConfig,
    dialogData?: any,
  ): DialogRef<T, R> {
    const dialogRef = new DialogRef<T, R>(
      config.id,
      dialogContainer,
      overlayRef,
    );

    const injector = this.createInjector<T>(
      dialogRef,
      dialogContainer,
      dialogData,
    );

    const contentRef = dialogContainer.attachComponentPortal(
      new ComponentPortal(component, undefined, injector),
    );

    dialogRef.componentInstance = contentRef.instance;
    dialogRef.updateSize(config.width, config.height);
    dialogRef.updatePosition(config.type);

    dialogContainer.onContentAttached();

    return dialogRef;
  }

  private hideNavigationBars(): void {
    this.navsHiddenByService = true;

    this.eventFacade?.dispatch(GlobalEventType.HideTopNav);
    this.eventFacade?.dispatch(GlobalEventType.HideBottomNav);
  }

  private showNavigationBars(): void {
    this.eventFacade?.dispatch(GlobalEventType.ShowTopNav);
    this.eventFacade?.dispatch(GlobalEventType.ShowBottomNav);
  }

  private hideFabs(): void {
    this.fabsHiddenByService = true;

    this.eventFacade?.dispatch(GlobalEventType.HideFabs);
  }

  private showFabs(): void {
    this.eventFacade?.dispatch(GlobalEventType.ShowFabs);
  }

  private attachDialogContainer(
    overlay: OverlayRef,
    dialogConfig: DialogConfig,
  ): any {
    const injector = Injector.create({
      parent: this.injector,
      providers: [{ provide: DIALOG_CONFIG, useValue: dialogConfig }],
    });

    const containerPortal = new ComponentPortal(
      DialogContainerComponent,
      undefined,
      injector,
    );

    const containerRef =
      overlay.attach<DialogContainerComponent>(containerPortal);

    return containerRef.instance;
  }

  private composeOverlay(config: DialogConfig): OverlayRef {
    const overlayConfig = this.composeOverlayConfig(config);

    return this.overlay.create(overlayConfig);
  }

  private composeOverlayConfig(dialogConfig: DialogConfig): OverlayConfig {
    const overlayConfig = new OverlayConfig({
      positionStrategy: this.overlay.position().global(),
      scrollStrategy: this.overlay.scrollStrategies.block(),
      disposeOnNavigation: dialogConfig.closeOnNavigation,
      ...dialogConfig,
    });

    if (dialogConfig.backdropClass) {
      overlayConfig.backdropClass = dialogConfig.backdropClass;
    }

    return overlayConfig;
  }

  private createInjector<T>(
    dialogRef: DialogRef<T>,
    dialogContainer: DialogContainerComponent,
    dialogData: any,
  ): Injector {
    const providers: StaticProvider[] = [
      { provide: DialogContainerComponent, useValue: dialogContainer },
      { provide: DIALOG_DATA, useValue: dialogData },
      { provide: DialogRef, useValue: dialogRef },
    ];

    return Injector.create({
      parent: this.injector,
      providers,
    });
  }
}
