import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromSocket from './socket.reducer';

const selectState = createFeatureSelector<fromSocket.State>(
  fromSocket.featureKey,
);

export const selectIsConnected = createSelector(
  selectState,
  (state) => state.isConnected,
);

export const selectIsConnecting = createSelector(
  selectState,
  (state) => state.isConnecting,
);
