import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { isNil } from 'lodash-es';
import { Observable, first, map, switchMap, tap } from 'rxjs';

import { PaginatedResult, PatchOp, uuid } from '@aw/shared/models';
import { BlobService } from '@aw/shared/services/blob';
import {
  composePatchOps,
  composeQueryParams,
  payloadToPatchOps,
} from '@aw/shared/utilities';

import { BusinessUnit } from '@aw/prypco/enums';
import { prypcoEnv } from '@aw/prypco/environment';
import { UtmService } from '@aw/prypco/services/utm';
import { AuthFacade } from '@aw/prypco/state/auth';
import { utmToMetadata } from '@aw/prypco/utils';

import {
  BlocksLeadAddressDocumentType,
  TransactionStatus,
} from './enums/block-service.enum';
import {
  BankAccount,
  BusinessBankAccount,
} from './models/entities/bank-account.model';
import { BlocksLeadDocument } from './models/entities/blocks-lead-document.model';
import { BlocksLead } from './models/entities/blocks-lead.model';
import {
  BlocksMemberProfile,
  BlocksMemberProfileAddress,
} from './models/entities/blocks-member-profile.model';
import { BlocksMemberReferral } from './models/entities/blocks-member-referral.model';
import {
  BlocksMemberRewards,
  BlocksMemberRewardsFilters,
} from './models/entities/blocks-member-rewards.model';
import { DirectDebitPaymentSources } from './models/entities/direct-debit-payment-sources.model';
import {
  PropertyDetails,
  PropertySummary,
} from './models/entities/property.model';
import { Referral } from './models/entities/referral.model';
import {
  BankTransferTransaction,
  TransactionSummary,
  TransactionSummaryFilters,
} from './models/entities/transaction.model';
import { Wallet } from './models/entities/wallet.model';
import { BankAccountCreationPayload } from './models/requests/bank-account-payload.model';
import { PropertyFilters } from './models/requests/property-filters.model';
import { DocumentDownloadResponse } from './models/responses/document-download-response.model';
import { OnfidoTokenResponse } from './models/responses/onfido-token-response.model';

@Injectable({ providedIn: 'root' })
export class BlocksService {
  readonly supportPhoneNumber = 'https://wa.me/800779726';

  private readonly httpClient = inject(HttpClient);

  private readonly authFacade = inject(AuthFacade);

  private readonly blobService = inject(BlobService);

  private readonly utmService = inject(UtmService);

  private readonly baseUrl = prypcoEnv.endpoints.propertyInvestment;

  private readonly leadBaseUrl = prypcoEnv.endpoints.leadBase;

  private readonly blocksLeadBaseUrl = prypcoEnv.endpoints.blocksLead;

  private readonly onboardingBaseUrl = prypcoEnv.endpoints.blocksOnboarding;

  private readonly dueDiligenceBaseUrl = prypcoEnv.endpoints.dueDiligence;

  private readonly waitList = prypcoEnv.endpoints.waitList;

  private readonly docsUrl = `${prypcoEnv.endpoints.leadBase}/Documents`;

  createOnboardingLead(): Observable<BlocksLead> {
    const metadata = utmToMetadata(this.utmService.getTags());

    return this.httpClient
      .post<BlocksLead>(`${this.onboardingBaseUrl}`, { metadata })
      .pipe(tap(() => this.utmService.deleteTags()));
  }

  getLead(leadId: uuid): Observable<BlocksLead> {
    return this.httpClient.get<BlocksLead>(
      `${this.blocksLeadBaseUrl}/${leadId}`,
    );
  }

  updateLead(
    leadId: uuid,
    payload: Partial<BlocksLead>,
    originalLead?: BlocksLead,
    skipOriginalLead = false,
  ): Observable<BlocksLead> {
    let patchOps: Array<PatchOp> = [];

    if (!originalLead || skipOriginalLead) {
      patchOps = payloadToPatchOps(payload);
    } else {
      patchOps = composePatchOps(originalLead, payload, true);
    }

    return this.httpClient.patch<BlocksLead>(
      `${this.blocksLeadBaseUrl}/${leadId}`,
      patchOps,
    );
  }

  getProperties(
    filters?: PropertyFilters,
  ): Observable<PaginatedResult<PropertySummary>> {
    const params = composeQueryParams(filters);

    return this.composePropertiesUrl([this.baseUrl, 'Properties']).pipe(
      switchMap((url) =>
        this.httpClient.get<PaginatedResult<PropertySummary>>(url, {
          params,
        }),
      ),
    );
  }

  getPropertyDetails(id: uuid): Observable<PropertyDetails> {
    return this.composePropertiesUrl([this.baseUrl, 'Properties', id]).pipe(
      switchMap((url) => this.httpClient.get<PropertyDetails>(url)),
    );
  }

  getOnfidoSdkToken(): Observable<OnfidoTokenResponse> {
    return this.httpClient.get<OnfidoTokenResponse>(
      `${this.dueDiligenceBaseUrl}/Onfido/token`,
    );
  }

  /**
   * Bank Accounts
   */

  getBankAccounts(): Observable<Array<BankAccount>> {
    return this.httpClient.get<Array<BankAccount>>(
      `${this.baseUrl}/wallet/banktransferaccounts`,
    );
  }

  createBankAccount(
    payload: BankAccountCreationPayload,
  ): Observable<BankAccount> {
    return this.httpClient.post<BankAccount>(
      `${this.baseUrl}/wallet/banktransferaccounts`,
      payload,
    );
  }

  deleteBankAccount(id: uuid): Observable<void> {
    return this.httpClient.delete<void>(
      `${this.baseUrl}/wallet/banktransferaccounts/${id}`,
    );
  }

  updateBankAccount(
    id: uuid,
    payload: Partial<BankAccount>,
  ): Observable<BankAccount> {
    const patchOps = payloadToPatchOps(payload);

    return this.httpClient.patch<BankAccount>(
      `${this.baseUrl}/wallet/banktransferaccounts/${id}`,
      patchOps,
    );
  }

  getBusinessBankAccount(): Observable<BusinessBankAccount> {
    return this.httpClient.get<BusinessBankAccount>(
      `${this.baseUrl}/wallet/businessaccount`,
    );
  }

  getTransactions(
    filters: TransactionSummaryFilters,
  ): Observable<PaginatedResult<TransactionSummary>> {
    const params = composeQueryParams(filters);
    return this.httpClient.get<PaginatedResult<TransactionSummary>>(
      `${this.baseUrl}/wallet/transactions`,
      { params },
    );
  }

  updateTransactionStatus(
    id: uuid,
    status: TransactionStatus,
  ): Observable<TransactionSummary> {
    const patchOp: PatchOp = {
      op: 'replace',
      path: 'status',
      value: status,
    };

    return this.httpClient.patch<TransactionSummary>(
      `${this.baseUrl}/wallet/transactions/${id}`,
      [patchOp],
    );
  }

  getWallet(): Observable<Wallet> {
    return this.httpClient.get<Wallet>(`${this.baseUrl}/wallet`);
  }

  topUpWallet(
    topUpPayload: BankTransferTransaction,
  ): Observable<TransactionSummary> {
    const formData = new FormData();

    formData.append('amount.amount', String(topUpPayload.amount.amount));
    formData.append('amount.currency', topUpPayload.amount.currency);
    formData.append('instrument', topUpPayload.transactionInstrument as string);

    if (!isNil(topUpPayload.bankTransferAccountId)) {
      formData.append(
        'bankTransferAccountId',
        topUpPayload.bankTransferAccountId,
      );
    }

    if (!isNil(topUpPayload.proofOfTransfer)) {
      formData.append('proofOfTransfer', topUpPayload.proofOfTransfer);
    }

    if (!isNil(topUpPayload.transactionReferenceNo)) {
      formData.append(
        'transactionReferenceNo',
        topUpPayload.transactionReferenceNo as string,
      );
    }

    return this.httpClient.post<TransactionSummary>(
      `${this.baseUrl}/wallet/topup`,
      formData,
    );
  }

  withdrawFromWallet(
    payload: BankTransferTransaction,
  ): Observable<TransactionSummary> {
    return this.httpClient.post<TransactionSummary>(
      `${this.baseUrl}/wallet/withdrawal`,
      payload,
    );
  }

  joinWaitingList(waitingListPayload: { email?: string; phone?: string }) {
    return this.httpClient.post(`${this.waitList}`, {
      businessUnit: BusinessUnit.Blocks,
      phoneNumber: waitingListPayload.phone,
      email: waitingListPayload.email,
    });
  }

  uploadDocument(
    leadId: uuid,
    type: BlocksLeadAddressDocumentType,
    content: File,
  ): Observable<
    HttpEvent<HttpProgressEvent> | HttpResponse<BlocksLeadDocument>
  > {
    const formData: FormData = new FormData();

    formData.append('BlocksLeadId', leadId);
    formData.append('Category', type);
    formData.append('Content', content);
    const headers = new HttpHeaders({ 'ngsw-bypass': '' });
    const req = new HttpRequest('POST', this.docsUrl, formData, {
      reportProgress: true,
      responseType: 'json',
      headers,
    });

    return this.httpClient.request(req) as any;
  }

  getAddressDocuments(leadId: uuid): Observable<Array<BlocksLeadDocument>> {
    const params = composeQueryParams({
      BlocksLeadId: leadId,
      Category: 'BLOCKS_LEAD_ADDRESS',
    });

    return this.httpClient.get<Array<BlocksLeadDocument>>(this.docsUrl, {
      params,
    });
  }

  deleteDocument(
    category: BlocksLeadAddressDocumentType,
    name: string,
    blocksLeadId: string,
  ): Observable<void> {
    return this.httpClient.delete<void>(this.docsUrl, {
      body: {
        category,
        name,
        blocksLeadId,
      },
    });
  }

  getMemberRewards(
    filters: BlocksMemberRewardsFilters = { take: 10, skip: 0 },
  ): Observable<PaginatedResult<BlocksMemberRewards>> {
    const url = `${this.leadBaseUrl}/Rewards/Gravty/Transactions`;
    const params = composeQueryParams(filters);
    return this.httpClient.get<PaginatedResult<BlocksMemberRewards>>(url, {
      params,
    });
  }

  getMemberProfile(): Observable<BlocksMemberProfile> {
    return this.httpClient.get<BlocksMemberProfile>(
      `${this.leadBaseUrl}/Profile/Blocks`,
    );
  }

  updateResidence(
    payload: Partial<BlocksMemberProfileAddress>,
    id: uuid,
  ): Observable<BlocksMemberProfileAddress> {
    const patchOps = payloadToPatchOps(payload);

    return this.httpClient.patch<BlocksMemberProfileAddress>(
      `${this.leadBaseUrl}/addresses/${id}`,
      patchOps,
    );
  }

  downloadDocument(
    category: string,
    name: string,
    transactionId?: uuid,
    propertyId?: uuid,
  ): Observable<void> {
    const params = composeQueryParams({
      category,
      name,
      transactionId,
      propertyId,
    });

    return this.httpClient
      .get<DocumentDownloadResponse>(`${this.baseUrl}/documents/download`, {
        params,
      })
      .pipe(
        tap(({ downloadUrl }) =>
          this.blobService.downloadWithUrl(downloadUrl, name),
        ),
        map(() => undefined),
      );
  }

  getReferralsList(): Observable<Array<BlocksMemberReferral>> {
    return this.httpClient.get<Array<BlocksMemberReferral>>(
      `${this.leadBaseUrl}/Rewards/Gravty/Referrals`,
    );
  }

  getReferralCode(): Observable<Referral> {
    return this.httpClient.get<Referral>(
      `${this.leadBaseUrl}/Referral?BusinessUnit=${BusinessUnit.Blocks}`,
    );
  }

  getDirectDebitPaymentSources(): Observable<DirectDebitPaymentSources> {
    return this.httpClient.get<DirectDebitPaymentSources>(
      `${this.baseUrl}/DirectDebit/PaymentSources`,
    );
  }

  private readonly composePropertiesUrl = (
    segments: Array<string>,
  ): Observable<string> =>
    this.authFacade.authenticated$.pipe(
      first(),
      map((isAuthenticated) => {
        const newSegments = [...segments];

        if (!isAuthenticated) {
          newSegments.splice(1, 0, 'Guest');
        }

        return newSegments.join('/');
      }),
    );
}
