import { AnimationEvent } from '@angular/animations';
import {
  ConfigurableFocusTrap,
  ConfigurableFocusTrapFactory,
  FocusMonitor,
  FocusOrigin,
} from '@angular/cdk/a11y';
import { _getFocusedElementPierceShadowDom } from '@angular/cdk/platform';
import {
  BasePortalOutlet,
  CdkPortalOutlet,
  ComponentPortal,
  TemplatePortal,
} from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  EmbeddedViewRef,
  HostBinding,
  Inject,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Subject } from 'rxjs';

import {
  DialogContainerAnimationState,
  dialogAnimations,
} from '../../animations/dialog.animations';
import { DialogState } from '../../enums/dialog-service.enums';
import { DialogAnimationEvent } from '../../models/dialog-animation-event.model';
import { DialogConfig } from '../../models/dialog-config.model';
import { DIALOG_CONFIG } from '../../tokens/dialog-config.token';

@Component({
  selector: 'po-dialog-container',
  templateUrl: 'dialog-container.component.html',
  animations: [dialogAnimations.dialogContainer],
  styleUrls: ['./dialog-container.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogContainerComponent extends BasePortalOutlet {
  static nextId = 0;

  @HostBinding('class.po-dialog-container')
  readonly identifierClass = true;

  @ViewChild(CdkPortalOutlet, { static: true }) portalOutlet: CdkPortalOutlet;

  animationState: DialogContainerAnimationState =
    DialogContainerAnimationState.Enter;

  closeInteractionType: FocusOrigin | null = null;

  readonly id: string;

  readonly animationStateChange$ = new Subject<DialogAnimationEvent>();

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(DIALOG_CONFIG) readonly config: DialogConfig,
    private readonly elementRef: ElementRef<HTMLDivElement>,
    private readonly focusTrapFactory: ConfigurableFocusTrapFactory,
    private readonly focusMonitor: FocusMonitor,
    private readonly changeDetector: ChangeDetectorRef,
  ) {
    super();

    this.id =
      this.config.id ||
      `po-dialog-container-${DialogContainerComponent.nextId++}`;
  }

  @ViewChild('aside', { static: true })
  private readonly aside: ElementRef<HTMLElement>;

  private focusTrap: ConfigurableFocusTrap;

  private elementFocusedBeforeDialogWasOpened?: HTMLElement;

  private get containsFocus() {
    const element = this.elementRef.nativeElement;

    const activeElement = _getFocusedElementPierceShadowDom();

    return element === activeElement || element.contains(activeElement);
  }

  onClick(event: MouseEvent | TouchEvent): void {
    if (
      !this.config.disableClose &&
      event.target === this.aside.nativeElement
    ) {
      event.stopPropagation();
      event.preventDefault();

      const backdrop = this.document.querySelector(
        '.cdk-overlay-backdrop',
      ) as HTMLElement;

      backdrop.click();
    }
  }

  onContentAttached() {
    this.focusTrap = this.focusTrapFactory.create(
      this.elementRef.nativeElement,
    );

    if (this.document) {
      this.elementFocusedBeforeDialogWasOpened =
        _getFocusedElementPierceShadowDom() || undefined;
    }

    this.trapFocus();
  }

  onAnimationStart(event: AnimationEvent) {
    const { toState, totalTime } = event;

    if (toState === DialogContainerAnimationState.Enter) {
      this.animationStateChange$.next({
        state: DialogState.Opening,
        totalTime,
      });
    } else if (
      toState === DialogContainerAnimationState.Exit ||
      toState === DialogContainerAnimationState.Void
    ) {
      this.animationStateChange$.next({
        state: DialogState.Closing,
        totalTime,
      });
    }
  }

  onAnimationDone(event: AnimationEvent) {
    const { toState, totalTime } = event;

    if (toState === DialogContainerAnimationState.Enter) {
      this.onOpenAnimationDone(totalTime);
    } else if (toState === DialogContainerAnimationState.Exit) {
      this.restoreFocus();
      this.animationStateChange$.next({ state: DialogState.Closed, totalTime });
    }
  }

  startExitAnimation(): void {
    this.animationState = DialogContainerAnimationState.Exit;
    this.changeDetector.markForCheck();
  }

  attachComponentPortal<T>(portal: ComponentPortal<T>): ComponentRef<T> {
    return this.portalOutlet.attachComponentPortal(portal);
  }

  attachTemplatePortal<C>(portal: TemplatePortal<C>): EmbeddedViewRef<C> {
    return this.portalOutlet.attachTemplatePortal(portal);
  }

  recaptureFocus() {
    if (!this.containsFocus) {
      this.trapFocus();
    }
  }

  restoreFocus() {
    const previousElement = this.elementFocusedBeforeDialogWasOpened;

    if (
      this.config.restoreFocus &&
      previousElement &&
      typeof previousElement.focus === 'function'
    ) {
      const activeElement = _getFocusedElementPierceShadowDom();
      const element = this.elementRef.nativeElement;

      if (
        !activeElement ||
        activeElement === this.document.body ||
        activeElement === element ||
        element.contains(activeElement)
      ) {
        if (this.focusMonitor) {
          this.focusMonitor.focusVia(
            previousElement,
            this.closeInteractionType,
          );
          this.closeInteractionType = null;
        } else {
          previousElement.focus();
        }
      }
    }

    if (this.focusTrap) {
      this.focusTrap.destroy();
    }
  }

  private onOpenAnimationDone(totalTime: number): void {
    this.trapFocus();

    this.animationStateChange$.next({ state: DialogState.Open, totalTime });
  }

  private trapFocus(): void {
    // Wait for change detection to run (micro task queue empty) so the content we might attempt to focus is ready
    setTimeout(() => {
      if (this.config.focusTarget) {
        const element = this.elementRef.nativeElement.querySelector(
          this.config.focusTarget,
        ) as HTMLElement;

        if (element && typeof element.focus === 'function') {
          element.focus();
        }
      } else {
        const element = this.elementRef.nativeElement;
        element.focus();
      }
    });
  }
}
