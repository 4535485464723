import { isNil } from 'lodash-es';

export const lowercaseCompare = (a: string, b: string): boolean => {
  if (isNil(a) || isNil(b)) {
    return false;
  }

  return a.toLowerCase() === b.toLowerCase();
};

export const lowercaseIncludes = (a: string, b: string): boolean => {
  if (isNil(a) || isNil(b)) {
    return false;
  }

  return a.toLowerCase().includes(b.toLowerCase());
};
