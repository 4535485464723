import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { InterceptorsModule } from '@aw/prypco/interceptors';

import { composeAppRoutes } from './app.routes';

@NgModule({
  imports: [
    RouterModule.forRoot(composeAppRoutes(), {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
    InterceptorsModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
