export enum JobTitle {
  System = 'SYSTEM',
  SysAdmin = 'SYSTEM_ADMINISTRATOR',
  SalesAgencyManager = 'SALES_AGENCY_MANAGER',
  SalesAgent = 'SALES_AGENT',
  Member = 'MEMBER',
  Telesales = 'TELESALES',
  MortgageBroker = 'MORTGAGE_BROKER',
  SuperMortgageAdvisorManager = 'SUPER_MORTGAGE_ADVISOR_MANAGER',
  MortgageAdvisorManagerAgency = 'MORTGAGE_ADVISOR_MANAGER_AGENCY',
  MortgageAdvisorManagerBroker = 'MORTGAGE_ADVISOR_MANAGER_BROKER',
  MortgageAdvisor = 'MORTGAGE_ADVISOR',
  MortgageAdvisorAgency = 'MORTGAGE_ADVISOR_AGENCY',
  MortgageAdvisorBroker = 'MORTGAGE_ADVISOR_BROKER',

  BlocksSysAdmin = 'BLOCKS_SYSTEM_ADMINISTRATOR',
  BlocksComplianceOfficer = 'BLOCKS_COMPLIANCE_OFFICER',
  BlocksOperationsAgent = 'BLOCKS_OPERATIONS_AGENT',
  BlocksFinanceAgent = 'BLOCKS_FINANCE_AGENT',
  BlocksTelecaller = 'BLOCKS_TELECALLER',
  BlocksRelationshipManager = 'BLOCKS_RELATIONSHIP_MANAGER',
}
