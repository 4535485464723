import { inject } from '@angular/core';
import { Route, Router } from '@angular/router';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { Observable, map, of, take, tap } from 'rxjs';

import { StubComponent } from '@aw/shared/components/stub';
import { SequenceGuard } from '@aw/shared/guards';

import { listingBackOfficeRoles } from '@aw/prypco/constants';
import { BusinessUnit, JobTitle, Module, Page } from '@aw/prypco/enums';
import {
  AuthGuard,
  AuthRedirectGuard,
  ResetPasswordGuard,
} from '@aw/prypco/guards/auth';
import { RoleGuard, RoleGuardData } from '@aw/prypco/guards/role';
import { BlocksPage } from '@aw/prypco/modules/blocks/enum';
import { BlocksPortalPage } from '@aw/prypco/modules/blocks/modules/portal/enum';
import { CorporatePortalPage } from '@aw/prypco/modules/corporate/modules/portal/enum';
import { DeviceService } from '@aw/prypco/services/device';
import { composeSeoRoute } from '@aw/prypco/services/seo';
import { AuthFacade } from '@aw/prypco/state/auth';

import { environment } from '../environments/environment';

const mortgageRoles: JobTitle[] = [
  JobTitle.SalesAgencyManager,
  JobTitle.SalesAgent,
  JobTitle.SysAdmin,
  JobTitle.Telesales,
  JobTitle.MortgageBroker,
  JobTitle.SuperMortgageAdvisorManager,
  JobTitle.MortgageAdvisorManagerAgency,
  JobTitle.MortgageAdvisorManagerBroker,
  JobTitle.MortgageAdvisor,
  JobTitle.MortgageAdvisorAgency,
  JobTitle.MortgageAdvisorBroker,
];

/**
 * Ugly way to fix a bug where the blocks mobile app sometimes opens the main corpo page
 * instead of the blocks module
 */
const blocksMobileAppMatcher = (): Observable<boolean> => {
  const deviceService = inject(DeviceService);
  const authFacade = inject(AuthFacade);
  const router = inject(Router);

  if (
    deviceService.isApp &&
    deviceService.appBusinessUnit === BusinessUnit.Blocks
  ) {
    return authFacade.authenticated$.pipe(take(1)).pipe(
      map((isAuthenticated) => {
        if (isAuthenticated) {
          return [Module.Blocks, BlocksPortalPage.Dashboard];
        }

        return [Module.Blocks, BlocksPage.AppLanding];
      }),
      tap((route) => void router.navigate(route)),
      map(() => false),
    );
  }

  return of(true);
};

const blocksAgentAppMatcher = (): Observable<boolean> => {
  const deviceService = inject(DeviceService);
  const router = inject(Router);
  const authFacade = inject(AuthFacade);

  if (deviceService.isApp && deviceService.appBusinessUnit === 'BACK_OFFICE') {
    return authFacade.jobTitle$.pipe(
      take(1),
      tap((jobTitle) => {
        if (jobTitle === JobTitle.Member) {
          void router.navigate([Page.AgentAppMember]);
        }
      }),
      map((jobTitle) => jobTitle !== JobTitle.Member),
    );
  }

  return of(true);
};

const baseRoutes: Array<Route> = [
  composeSeoRoute(
    {
      path: '',
      pathMatch: 'full',
      canMatch: [blocksMobileAppMatcher],
      loadChildren: () =>
        import('@aw/prypco/modules/corporate/layout').then(
          (m) => m.CorporateLayoutModule,
        ),
    },
    {
      title: `PRYPCO: Mortgage, Real Estate Investments & Golden Visa Services`,
      description: `Get the best mortgage offers, golden visa & fractional ownership of properties in UAE with PRYPCO's simple prop-tech platform. We make real estate experience easy & accessible for all`,
    },
  ),

  {
    path: CorporatePortalPage.CookiePolicy,
    pathMatch: 'full',
    loadComponent: () =>
      import(
        '@aw/prypco/modules/corporate/modules/portal/pages/cookie-policy'
      ).then((m) => m.CookiePolicyPage),
  },

  {
    path: CorporatePortalPage.PrivacyPolicy,
    pathMatch: 'full',
    loadComponent: () =>
      import(
        '@aw/prypco/modules/corporate/modules/portal/pages/privacy-policy'
      ).then((m) => m.PrivacyPolicyPage),
  },
  {
    path: CorporatePortalPage.ContactUs,
    pathMatch: 'full',
    loadComponent: () =>
      import(
        '@aw/prypco/modules/corporate/modules/portal/pages/contact-us'
      ).then((m) => m.ContactUsPage),
  },
  {
    path: CorporatePortalPage.AboutAmira,
    pathMatch: 'full',
    loadComponent: () =>
      import(
        '@aw/prypco/modules/corporate/modules/portal/pages/about-amira'
      ).then((m) => m.AboutAmiraPage),
  },
  {
    path: CorporatePortalPage.AboutUs,
    pathMatch: 'full',
    loadComponent: () =>
      import('@aw/prypco/modules/corporate/modules/portal/pages/about-us').then(
        (m) => m.AboutUsPage,
      ),
  },
  {
    path: CorporatePortalPage.TermsAndConditions,
    pathMatch: 'full',
    loadComponent: () =>
      import(
        '@aw/prypco/modules/corporate/modules/portal/pages/terms-and-conditions'
      ).then((m) => m.TermsAndConditionsPage),
  },
  { path: 'auth', component: MsalRedirectComponent },
  {
    path: 'redirect',
    canActivate: [MsalGuard],
    component: StubComponent,
  },
  {
    path: Module.AuthRedirect,
    canActivate: [AuthRedirectGuard],
    component: StubComponent,
  },
  {
    path: Module.Mortgage,
    loadChildren: () =>
      import('@aw/prypco/modules/mortgage/layout').then(
        (m) => m.MortgageLayoutModule,
      ),
  },

  {
    path: Module.Listing,
    canLoad: [SequenceGuard],
    data: {
      guards: [AuthGuard, RoleGuard],
      [RoleGuardData.Roles]: [...listingBackOfficeRoles],
    },
    loadChildren: () =>
      import('@aw/prypco/modules/listing/layout').then(
        (m) => m.BACK_OFFICE_LISTING_ROUTES,
      ),
  },

  composeSeoRoute(
    {
      path: Module.KnowledgeHub,
      loadChildren: () =>
        import('@aw/prypco/modules/knowledge-hub/modules/portal/layout').then(
          (m) => m.KnowledgeHubPortalLayoutModule,
        ),
    },
    {
      title: `Blogs, FAQs & Glossary for all things PRYPCO, real estate & UAE`,
      description: `All about UAE's real estate trends, property market analysis, investing insights, expert opinions & more sourced by PRYPCO's expert team. Get all your questions about real estate answered with our blogs, FAQs, Glossary and more.`,
    },
  ),
  // TODO: Move this to the actual mortgage layout module
  {
    path: Module.BackOffice,
    canMatch: [blocksAgentAppMatcher],
    canLoad: [SequenceGuard],
    data: {
      guards: [AuthGuard, RoleGuard],
      [RoleGuardData.Roles]: mortgageRoles,
    },
    loadChildren: () =>
      import('@aw/prypco/modules/mortgage/modules/back-office/layout').then(
        (m) => m.MortgageBackOfficeLayoutModule,
      ),
  },
  {
    path: `${Module.BackOffice}/${Page.SignUpSuccess}`,
    pathMatch: 'full',
    canMatch: [blocksAgentAppMatcher],
    canLoad: [SequenceGuard],
    loadComponent: () =>
      import('@aw/prypco/modules/mortgage/modules/back-office/layout').then(
        (m) => m.BackOfficeSuccessSignupComponent,
      ),
  },
  // TODO: Remove, only kept for backwards compatibility
  {
    path: `${Module.Mortgage}/${Module.BackOffice}`,
    redirectTo: Module.BackOffice,
  },
  // TODO: Remove, only kept for backwards compatibility
  {
    path: Module.Portal,
    redirectTo: `${Module.Mortgage}/${Module.Dashboard}`,
  },

  {
    path: Module.KnowledgeHub,
    loadChildren: () =>
      import('@aw/prypco/modules/knowledge-hub/modules/portal/layout').then(
        (m) => m.KnowledgeHubPortalLayoutModule,
      ),
  },
  {
    path: Module.Services,
    loadChildren: () =>
      import('@aw/prypco/modules/services/layout').then(
        (m) => m.ServicesLayoutModule,
      ),
  },
  {
    path: Module.Profile,
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('@aw/prypco/modules/profile/layout').then(
        (m) => m.ProfileLayoutModule,
      ),
  },
  {
    path: Page.ResetPassword,
    canActivate: [ResetPasswordGuard],
    component: StubComponent,
  },
  {
    path: 'signup/salesagent',
    loadComponent: () =>
      import('@aw/prypco/pages/sales-agent-signup').then(
        (m) => m.SalesAgentSignUpPage,
      ),
  },
  {
    path: Page.SignIn,
    loadComponent: () =>
      import('@aw/prypco/pages/sign-in').then((m) => m.SignInPage),
  },
  {
    path: Page.AgentAppMember,
    loadComponent: () =>
      import('@aw/prypco/pages/agent-app-member').then(
        (m) => m.AgentAppMemberPage,
      ),
  },
];

export const composeAppRoutes = (): Array<Route> => {
  const routes: Array<Route> = [...baseRoutes];

  const { blocks, devModule } = environment.featureFlags;

  if (devModule) {
    routes.push(
      composeSeoRoute(
        {
          path: Module.Dev,
          canLoad: [AuthGuard],
          loadChildren: () =>
            import('@aw/prypco/modules/dev/layout').then(
              (m) => m.DevLayoutModule,
            ),
        },
        {
          title: `PRYPCO: Development`,
          description: `Prypoco development module`,
        },
      ),
    );
  }

  if (blocks.portal) {
    routes.push(
      composeSeoRoute(
        {
          path: Module.Blocks,
          loadChildren: () =>
            import('@aw/prypco/modules/blocks/layout').then(
              (m) => m.BlocksLayoutModule,
            ),
        },

        {
          title: `Invest in Dubai's Real Estate (starting AED 500) with our Crowdfunding Platform - PRYPCO Blocks`,
          description: `Invest in Dubai's real estate with our fractional ownership platform and earn passive income from anywhere in the world. Invest as little as AED 500 with PRYPCO Blocks, regulated by DFSA and own a fraction of property in UAE.`,
        },
      ),
    );
  }

  if (blocks.comingSoon) {
    routes.push(
      composeSeoRoute(
        {
          path:
            blocks.portal && blocks.comingSoon
              ? `${Module.Blocks}/coming-soon`
              : Module.Blocks,
          loadChildren: () =>
            import(
              '@aw/prypco/modules/blocks/modules/portal/pages/blocks-coming-soon'
            ).then((m) => m.BlocksComingSoonModule),
        },
        {
          title: `Blocks: Own a fraction of a property in UAE starting at AED 500`,
          description: `Invest in a fraction or part of property in UAE with PRYPCO Blocks. Invest as low as AED 500 and enjoy passive income on your real estate investment`,
        },
      ),
    );
  }

  routes.push({
    path: '**',
    redirectTo: '',
  });

  return routes;
};
