import {
  AnimationTriggerMetadata,
  animate,
  animateChild,
  group,
  query,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export enum DialogContainerAnimationState {
  Void = 'void',
  Exit = 'exit',
  Enter = 'enter',
}

export const dialogAnimations: {
  readonly dialogContainer: AnimationTriggerMetadata;
} = {
  dialogContainer: trigger('dialogContainer', [
    state(
      `${DialogContainerAnimationState.Void}, ${DialogContainerAnimationState.Exit}`,
      style({ opacity: 0, transform: 'scale(0.7)' }),
    ),
    state(
      `${DialogContainerAnimationState.Enter}`,
      style({ transform: 'none' }),
    ),
    transition(
      `* => ${DialogContainerAnimationState.Enter}`,
      group([
        animate(
          '150ms cubic-bezier(0, 0, 0.2, 1)',
          style({ transform: 'none', opacity: 1 }),
        ),
        query('@*', animateChild(), { optional: true }),
      ]),
    ),
    transition(
      `* => ${DialogContainerAnimationState.Void}, * => ${DialogContainerAnimationState.Exit}`,
      group([
        animate('75ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({ opacity: 0 })),
        query('@*', animateChild(), { optional: true }),
      ]),
    ),
  ]),
};
