import { HttpParams } from '@angular/common/http';
import { isNil, isObject, keys } from 'lodash-es';

import { PatchOp } from '@aw/shared/models';

export const composeQueryParams = <T>(payload?: T): HttpParams => {
  let params = new HttpParams();

  if (!isNil(payload) && isObject(payload)) {
    const payloadKeys = keys(payload) as Array<keyof T>;

    payloadKeys.forEach((key) => {
      if (Array.isArray(payload[key])) {
        (payload[key] as []).forEach((item) => {
          const value = isNil(item) ? '' : item;
          params = params.append(key.toString(), (value as any).toString());
        });
      } else {
        const value = isNil(payload[key]) ? '' : payload[key];

        params = params.append(key.toString(), (value as any).toString());
      }
    });
  }

  return params;
};

export const payloadToPatchOps = <T>(payload: T): Array<PatchOp> => {
  const updatedKeys = keys(payload) as Array<keyof T>;

  return updatedKeys.map(
    (key) => new PatchOp(key as string, 'replace', payload[key] as any),
  );
};
