import { isString } from 'lodash-es';

import { NotificationType } from '@aw/shared/constants';

import { Text } from '@aw/prypco/models';
import { ReferenceDataCatalogue } from '@aw/prypco/services/reference-data';

export class SnackBarConfig {
  readonly message: Text;

  readonly title: Text;

  constructor(
    readonly type: NotificationType,
    private readonly _message: Text | string,
    private readonly _title?: Text | string,
    readonly persistent = false,
  ) {
    if (isString(this._message)) {
      let catalogue = ReferenceDataCatalogue.Text;

      switch (type) {
        case 'error':
          catalogue = ReferenceDataCatalogue.Error;
          break;
        case 'success':
          catalogue = ReferenceDataCatalogue.Success;
          break;
        default:
          catalogue = ReferenceDataCatalogue.Text;
          break;
      }

      this.message = new Text(this._message as string, catalogue);
    } else {
      this.message = this._message as Text;
    }

    if (isString(this._title)) {
      this.title = new Text(
        this._title as string,
        ReferenceDataCatalogue.NotificationTitle,
      );
    } else {
      this.title = this._title as Text;
    }
  }
}
