import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AccountInfo, InteractionStatus } from '@azure/msal-browser';
import { isFunction, isNil } from 'lodash-es';
import { filter, firstValueFrom, map } from 'rxjs';

import { tokenScope } from '@aw/prypco/environment';
import { ReferenceDataService } from '@aw/prypco/services/reference-data';
import { AuthFacade } from '@aw/prypco/state/auth';

// Filtering for all interactions to be completed
const msalInteractionsCompleted = async (
  msalBroadCastService: MsalBroadcastService,
): Promise<boolean> =>
  firstValueFrom(
    msalBroadCastService.inProgress$.pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      map(() => true),
    ),
  );

// Set the active account in the msal service, if one isn't already set
const setActiveMsalAccount = (msalService: MsalService): AccountInfo | null => {
  const activeAccount = msalService.instance.getActiveAccount();

  if (isNil(activeAccount)) {
    const accounts = msalService.instance.getAllAccounts();

    if (accounts.length) {
      msalService.instance.setActiveAccount(accounts[0]);

      return accounts[0];
    }
  }

  return activeAccount;
};

/**
 * Will attempt to refresh the current token using the stored refresh token.
 * If the refresh token is expired, the user will be logged out.
 * This is needed in order to prevent an active account being set with no valid tokens.
 * @param msalService MsalService
 */
const attemptTokenRefresh = async (msalService: MsalService) => {
  const account = setActiveMsalAccount(msalService);

  if (account && isFunction(msalService.acquireTokenSilent)) {
    await firstValueFrom(
      msalService.acquireTokenSilent({ scopes: [tokenScope] }),
    ).catch(async () => {
      await firstValueFrom(msalService.logout());
    });
  }
};

const initAuthState = async (authFacade: AuthFacade) => {
  authFacade.init();

  await firstValueFrom(
    authFacade.initialized$.pipe(filter((initialized) => initialized)),
  );
};

export const appInitializer =
  (
    msalService: MsalService,
    msalBroadCastService: MsalBroadcastService,
    referenceDataService: ReferenceDataService,
    authFacade: AuthFacade,
  ) =>
  async () => {
    await msalService.instance.initialize();
    await msalService.instance.handleRedirectPromise();
    await msalInteractionsCompleted(msalBroadCastService);

    await firstValueFrom(referenceDataService.init());

    await attemptTokenRefresh(msalService);
    await initAuthState(authFacade);
  };
