import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';

import { RoutingHistoryEntry } from './models/routing-history-entry.model';

@UntilDestroy()
@Injectable()
export class RoutingHistoryService {
  get previousUrl(): RoutingHistoryEntry | undefined {
    return this.buffer[this.buffer.length - 2];
  }

  get currentUrl(): RoutingHistoryEntry {
    return this.buffer[this.buffer.length - 1];
  }

  get history(): Array<RoutingHistoryEntry> {
    return [...this.buffer];
  }

  constructor(private readonly router: Router) {
    this.pushToBuffer(this.router.url);
  }

  private readonly buffer: Array<RoutingHistoryEntry> = [];

  private readonly maxBufferSize = 5;

  init(): void {
    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((event) => event instanceof NavigationEnd),
      )
      .subscribe({
        next: (event) => {
          const url = (event as NavigationEnd).urlAfterRedirects;

          this.pushToBuffer(url);
        },
      });
  }

  private pushToBuffer(newUrl: string): void {
    if (this.buffer.length === this.maxBufferSize) {
      this.buffer.shift();
    }

    this.buffer.push(new RoutingHistoryEntry(newUrl));
  }
}
