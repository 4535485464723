import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { remove } from 'lodash-es';
import { filter, first, interval, takeUntil } from 'rxjs';

import { NotificationType } from '@aw/shared/constants';

import { Text } from '@aw/prypco/models';

import { SnackBarComponent } from './components/snackbar/snackbar.component';
import { SnackBarConfig } from './models/snackbar-config.model';
import { SnackBarRef } from './models/snackbar-ref.model';
import { SnackBarServiceBase } from './models/snackbar-service-base.model';
import { SNACKBAR_REF } from './tokens/snackbar-ref.token';

@UntilDestroy()
@Injectable()
export class SnackBarService implements SnackBarServiceBase {
  constructor(
    private readonly overlay: Overlay,
    private readonly injector: Injector,
    private readonly router: Router,
  ) {
    this.initNavigationListener();
  }

  private readonly maxQueuedNotifications = 6;

  /**
   * The service will automatically close the notification after this amount of time,
   * if the notification was not closed by the component
   */
  private readonly defaultTimeout = 8000;

  /**
   * Timeout used to destroy a notification, if the component itself fails to do so
   */
  private readonly destructionFallbackTimeout = 1500;

  private openNotifications: Array<SnackBarRef> = [];

  private notificationQueue: Array<SnackBarConfig> = [];

  private get isQueueFull(): boolean {
    return this.notificationQueue.length === this.maxQueuedNotifications;
  }

  success(message: string | Text, title?: string | Text): void {
    const config = new SnackBarConfig('success', message, title);

    this.notify(config);
  }

  info(message: string | Text, title?: string | Text): void {
    const config = new SnackBarConfig('info', message, title);

    this.notify(config);
  }

  warning(message: string | Text, title?: string | Text): void {
    const config = new SnackBarConfig('warning', message, title);

    this.notify(config);
  }

  error(message: string | Text, title?: string | Text): void {
    const config = new SnackBarConfig('error', message, title);

    this.notify(config);
  }

  persistent(
    type: NotificationType,
    message: string | Text,
    title?: string | Text,
  ): void {
    const config = new SnackBarConfig(type, message, title, true);

    this.notify(config);
  }

  closeAll(): void {
    this.notificationQueue = [];

    this.openNotifications.forEach((notification) => {
      notification.close();
    });
  }

  private initNavigationListener(): void {
    this.router.events
      .pipe(
        untilDestroyed(this),
        filter(
          (event) =>
            event instanceof NavigationStart &&
            this.openNotifications.length > 0,
        ),
      )
      .subscribe(() => {
        this.closeAll();
      });
  }

  private notify(config: SnackBarConfig): void {
    if (this.isQueueFull) {
      return;
    }

    if (this.openNotifications.length) {
      this.notificationQueue.push(config);
    } else {
      this.openNotification(config);
    }
  }

  private openNotification(config: SnackBarConfig): void {
    const overlayRef = this.composeOverlayRef();

    const notificationRef = new SnackBarRef(overlayRef, config);
    const portal = this.composePortal(notificationRef);

    this.openNotifications.push(notificationRef);

    if (!config.persistent) {
      this.handleAutomaticClosure(notificationRef);
    }

    this.handleDestruction(notificationRef);

    overlayRef.attach(portal);
  }

  private composeOverlayRef(): OverlayRef {
    const positionStrategy = this.overlay
      .position()
      .global()
      .top('24px')
      .right('24px');

    return this.overlay.create({ positionStrategy });
  }

  private composePortal(
    notificationRef: SnackBarRef,
  ): ComponentPortal<SnackBarComponent> {
    const injector = this.composeInjector(notificationRef);

    return new ComponentPortal(SnackBarComponent, null, injector);
  }

  private composeInjector(notificationRef: SnackBarRef): Injector {
    return Injector.create({
      parent: this.injector,
      providers: [
        {
          provide: SNACKBAR_REF,
          useValue: notificationRef,
        },
      ],
    });
  }

  /**
   * Automatically close the notification after a set amount of time
   */
  private handleAutomaticClosure(notificationRef: SnackBarRef): void {
    interval(this.defaultTimeout)
      .pipe(first(), takeUntil(notificationRef.close$))
      .subscribe(() => {
        notificationRef.close();

        this.initDestructionFallback(notificationRef);
      });
  }

  private handleDestruction(notificationRef: SnackBarRef): void {
    notificationRef.destroyed$.pipe(first()).subscribe((id) => {
      remove(this.openNotifications, { id });

      const queuedNotification = this.notificationQueue.pop();

      if (queuedNotification) {
        this.notify(queuedNotification);
      }
    });
  }

  /**
   * Fallback logic that will destroy the overlay if the notification component itself
   * fails to do so after a set amount of time elapses following the triggering of the
   * closure of the notification.
   */
  private initDestructionFallback(notificationRef: SnackBarRef): void {
    interval(this.destructionFallbackTimeout)
      .pipe(takeUntil(notificationRef.destroyed$))
      .subscribe(() => {
        notificationRef.destroy();
      });
  }
}
