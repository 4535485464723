import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { AuthFacade } from '@aw/prypco/state/auth';
import { SplashScreenFacade } from '@aw/prypco/state/splash-screen';

@Injectable({ providedIn: 'root' })
export class ResetPasswordGuard implements CanActivate {
  constructor(
    private readonly authFacade: AuthFacade,
    private readonly splashScreenFacade: SplashScreenFacade,
  ) {}

  canActivate() {
    this.splashScreenFacade.setLoading('RESET_PASSWORD', true);

    this.authFacade.resetPassword();

    return true;
  }
}
