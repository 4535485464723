import { differenceInMilliseconds } from 'date-fns';

import { UploadProgressEvent } from '@aw/shared/models';

export const computeUploadProgress = (event: UploadProgressEvent): number =>
  Math.round((100 * event.loaded) / (event.total || 1));

export const computeUploadSpeed = (
  previousEvent: UploadProgressEvent,
  currentEvent: UploadProgressEvent,
): number => {
  const diffInMilliseconds = differenceInMilliseconds(
    currentEvent.timestamp,
    previousEvent.timestamp,
  );

  const uploadedSince = currentEvent.loaded - previousEvent.loaded;
  const uploadedPerMillisecond = Math.round(uploadedSince / diffInMilliseconds);

  return uploadedPerMillisecond * 1_000;
};
