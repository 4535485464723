export const SVG_ICON_NAMES = [
  '',
  'ac_unit',
  'admin_panel_settings',
  'android',
  'app-store',
  'app-google-play',
  'app-play-store',
  'arrow-back',
  'arrow-down',
  'arrow-forward',
  'arrow-left',
  'arrow-narrow-left',
  'arrow-narrow-right',
  'arrow-open',
  'arrow-right',
  'arrow-up',
  'auto_meeting_room',
  'auto_stories',
  'balcony',
  'bar',
  'bathroom',
  'beaker',
  'bed',
  'bell',
  'blender',
  'blocks-dfsa',
  'briefcase',
  'burger',
  'burj_khalifa',
  'business-unit-radio',
  'calculator',
  'calendar',
  'canal_view',
  'cancel',
  'cart',
  'cash',
  'cash-coin',
  'celebration',
  'chart-bar',
  'chat',
  'check-circle',
  'check-circle-filled',
  'checkmark',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'chevron-up',
  'circle-spinner',
  'clear',
  'clock',
  'close',
  'code_bracket',
  'collapsing-cubes',
  'copy',
  'csv',
  'cube',
  'cube-blocks',
  'cube-blocks-gradient',
  'cube-colored',
  'cube-gradient',
  'cube-mortgage',
  'cube-outline',
  'curtains',
  'customer',
  'dashboard',
  'dfsa',
  'diversity',
  'domain',
  'download',
  'dropdown-arrow',
  'email',
  'exclamation-circle',
  'excel',
  'exclude',
  'expand',
  'eye',
  'face_4',
  'facebook',
  'ferris_wheel',
  'file-copy',
  'filter-list',
  'financial-projection',
  'financial_projection',
  'fitness_center',
  'folder',
  'fractional-ownership',
  'garage_home',
  'glossary',
  'golden-visa',
  'golden-visa-menu',
  'golf_course',
  'google-play',
  'grade',
  'grid',
  'gym',
  'help',
  'help_outlined',
  'high_floor',
  'home',
  'home_health',
  'home_pin',
  'home_work',
  'hot_tub',
  'house',
  'hub',
  'hub-menu',
  'image',
  'image-gallery',
  'inbox',
  'info',
  'instagram',
  'investment-memo',
  'investment_report',
  'kitchen',
  'landscape',
  'linkedin',
  'loan',
  'local_convenience_store',
  'local_mall',
  'location_automation',
  'location_city',
  'lock',
  'low_floor',
  'mail',
  'mastercard',
  'market-report',
  'market_report',
  'meeting_chart',
  'menu',
  'menu-alt-2',
  'messenger',
  'mid_floor',
  'minus',
  'more',
  'more-horizontal',
  'NewMortgage',
  "KnowledgeHub",
  'Golden Visa',
  'Blocks',
  'mortgage',
  'mortgage-check',
  'moving',
  'muted',
  'nature',
  'new_release',
  'night_shelter',
  'nx',
  'office',
  'other_houses',
  'outdoor_grill',
  'pause',
  'pdf',
  'pencil',
  'people',
  'person',
  'person_play',
  'pets',
  'phone',
  'phoneNew',
  'play-arrow-outline',
  'play',
  'plus',
  'plus-minus-morph',
  'plus_box',
  'pool',
  'preview',
  'properties',
  'property-brochure',
  'property_brochure',
  'prypco',
  'prypco_white',
  'prypco-about-col',
  'prypco-blocks',
  'prypco-blocks-col',
  'prypco-blocks-dfsa',
  'prypco-blocks-regulated',
  'prypco-golden-visa',
  'prypco-hub',
  'prypco-hub-col',
  'prypco-manage',
  'prypco-mortgage',
  'prypco-mortgage-col',
  'prypco-o-cube',
  'prypco-rnpl',
  'prypco-rnpl-col',
  'prypco-services',
  'prypco-visa-services',
  'prypco-services-col',
  'prypco_agents',
  'quote',
  'quote_outlined',
  'receipt_long',
  'rectangle-grid',
  'recycle',
  'redo',
  'refresh',
  'request_page',
  'restaurant',
  'rnpl',
  'rnpl-mobile',
  'school',
  'search',
  'search-circle',
  'selector',
  'settings',
  'share',
  'sofa',
  'sort-asc',
  'sort-desc',
  'spa',
  'sports-gymnastics',
  'stacked_cubes',
  'stadium',
  'star',
  'star_half',
  'store',
  'subway',
  'success',
  'suite',
  'summarize',
  'swim',
  'telegram',
  'tiktok',
  'trash-can',
  'trash-can-outline',
  'triangle-downward',
  'tune',
  'twitter',
  'union',
  'unmuted',
  'users',
  'valuation-report',
  'valuation_report',
  'villa',
  'wallet',
  'visa-services',
  'visa',
  'wardrobe',
  'warning',
  'warranty-badge-highlight',
  'whatsapp',
  'whatsappNew',
  'x',
] as const;
