import { animate, style, transition, trigger } from '@angular/animations';

import { SnackBarAnimationState } from '../types/type';

const composeTransition = (
  from: 'void' | SnackBarAnimationState,
  to: SnackBarAnimationState,
): string => `${from} => ${to}`;

const transitionStyle = style({ transform: 'translateX(0.5rem)', opacity: 0 });

export const snackbarAnimation = [
  trigger('snackbar', [
    transition(composeTransition('void', 'open'), [
      transitionStyle,
      animate('300ms cubic-bezier(0, 0, 0.2, 1)'),
    ]),
    transition(composeTransition('open', 'close'), [
      animate('100ms cubic-bezier(0.4, 0, 1, 1)', transitionStyle),
    ]),
  ]),
];
