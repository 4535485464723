export enum GlobalEventType {
  MortgageLeadCreated = 'MORTGAGE_LEAD_CREATED',
  ServicesLeadCreated = 'SERVICES_LEAD_CREATED',
  RefDataChanged = 'REF_DATA_CHANGED',
  DisableGlobalScroll = 'DISABLE_GLOBAL_SCROLL',
  EnableGlobalScroll = 'ENABLE_GLOBAL_SCROLL',
  HideBottomNav = 'HIDE_BOTTOM_NAV',
  ShowBottomNav = 'SHOW_BOTTOM_NAV',
  HideTopNav = 'HIDE_TOP_NAV',
  ShowTopNav = 'SHOW_TOP_NAV',
  HideFabs = 'HIDE_FABS',
  ShowFabs = 'SHOW_FABS',
  HideCartFab = 'HIDE_CART_FAB',
  ShowCartFab = 'SHOW_CART_FAB',
  TeamMemberCreated = 'TEAM_MEMBER_CREATED',
}
