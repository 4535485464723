import { Injectable, inject } from '@angular/core';
import { noop } from 'lodash-es';

import { ENV, Environment } from '@aw/prypco/environment';

import { AmplitudeService } from './amplitude.abstract';
import { AmplitudeTrackEvent } from './model/amplitude-event.model';
import { AmplitudeUserPropertyList } from './model/amplitude-user.model';

@Injectable()
export class AmplitudeNoopService extends AmplitudeService {
  private readonly env = inject(ENV) as Environment;

  track(trackEvent: AmplitudeTrackEvent): void {
    if (this.env.externalScripts.amplitude.debug) {
      // eslint-disable-next-line no-restricted-syntax
      console.debug(trackEvent);
    }

    noop();
  }

  setUserProperty(propList: AmplitudeUserPropertyList): void {
    if (this.env.externalScripts.amplitude.debug) {
      // eslint-disable-next-line no-restricted-syntax
      console.debug(propList);
    }

    noop();
  }

  set shouldTrack(value: boolean) {
    noop();
  }

  set userId(userId: string) {
    noop();
  }

  get deviceId(): string | undefined {
    return undefined;
  }
}
