// eslint-disable-next-line @typescript-eslint/ban-ts-comment

/* eslint-disable */
import { HotjarScript } from '@aw/prypco/environment';

const hotJarLoader = (h, o, t, j, hjId: number, hjsv: number) => {
  h.hj =
    h.hj ||
    function () {
      (h.hj.q = h.hj.q || []).push(arguments);
    };
  h._hjSettings = { hjid: hjId, hjsv: hjsv };
  const a = o.getElementsByTagName('body')[0];
  const r = o.createElement('script');
  r.async = 1;
  r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
  a.appendChild(r);
};

export const initHotJar = (script: HotjarScript) => {
  const { hjid, hjsv } = script;

  try {
    hotJarLoader(
      window,
      document,
      'https://static.hotjar.com/c/hotjar-',
      '.js?sv=',
      hjid,
      hjsv,
    );
  } catch (e) {
    console.error('Could not configure hotjar');
  }
};
