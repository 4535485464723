import { AccountInfo } from '@azure/msal-browser';
import { Action, createReducer, on } from '@ngrx/store';

import { BusinessUnit } from '@aw/prypco/enums';
import { AccountStates, ExternalIdentifier } from '@aw/prypco/services/user';

import * as AuthActions from './auth.actions';
import { MemberInfoCustomer } from './auth.effects';
import { setAccountState } from './auth.util';
import { LeadIdRecord } from './models/entities/lead-id-record.model';

export const featureKey = 'auth';

export interface AuthPartialState {
  readonly [featureKey]: State;
}

export interface State {
  account?: AccountInfo;
  accountStates?: Record<BusinessUnit, AccountStates>;
  customer?: MemberInfoCustomer;

  externalIdentifiers: Array<ExternalIdentifier>;
  leadIdRecord: LeadIdRecord;
  initialized: boolean;
  loading: boolean;
}

export const initialState: State = {
  account: undefined,
  accountStates: undefined,
  customer: undefined,
  externalIdentifiers: [],
  leadIdRecord: {},
  initialized: false,
  loading: false,
};

const authReducer = createReducer(
  initialState,
  on(AuthActions.init, () => ({
    ...initialState,
    initialized: false,
    loading: true,
  })),
  on(
    AuthActions.setActiveAccount,
    (
      state,
      { account, leadIdRecord, customer, accountStates, externalIdentifiers },
    ) => ({
      ...state,
      leadIdRecord: { ...leadIdRecord },
      accountStates,
      externalIdentifiers,
      initialized: true,
      loading: false,
      customer,
      account: state.account
        ? {
            ...state.account,
            ...account,
            idTokenClaims: {
              ...state.account.idTokenClaims,
              ...account?.idTokenClaims,
            },
          }
        : account,
    }),
  ),
  on(AuthActions.setLeadId, (state, { leadId, businessUnit }) => ({
    ...state,
    leadIdRecord: {
      ...state.leadIdRecord,
      [businessUnit]: leadId,
    },
  })),
  on(AuthActions.setAccountStates, (state, { accountStates }) => ({
    ...state,
    accountStates,
    loading: false,
  })),
  on(AuthActions.refreshAccountStates, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    AuthActions.setAccountState,
    (state, { businessUnit, accountState, value }) => ({
      ...state,
      accountStates: setAccountState(state, businessUnit, accountState, value),
    }),
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return authReducer(state, action);
}
