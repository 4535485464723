import { createAction, props } from '@ngrx/store';

export const connect = createAction('[Socket] Connect');

export const connected = createAction(
  '[Socket] Connected',
  props<{ userIdentifier: string }>(),
);

export const connectionFailure = createAction('[Socket] Connection Failure');
