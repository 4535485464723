import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { SplashScreenFacade } from './splash-screen.facade';
import * as fromSplashScreen from './splash-screen.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(
      fromSplashScreen.featureKey,
      fromSplashScreen.reducer,
    ),
  ],
  providers: [SplashScreenFacade],
})
export class SplashScreenStateModule {}
