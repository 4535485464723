import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class PatchHeaderInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (request.method === 'PATCH') {
      return next.handle(this.addPatchHeader(request));
    }

    return next.handle(request);
  }

  private addPatchHeader(request: HttpRequest<any>): HttpRequest<any> {
    const headers = request.headers.set(
      'Content-Type',
      'application/json-patch+json',
    );

    return request.clone({ headers });
  }
}
