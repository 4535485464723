/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';

import * as SocketSelectors from './socket.selectors';

@Injectable()
export class SocketFacade {
  protected readonly store = inject(Store);

  readonly isConnected$ = this.store.select(SocketSelectors.selectIsConnected);

  readonly isConnecting$ = this.store.select(
    SocketSelectors.selectIsConnecting,
  );
}
