<po-snackbar-container>
  <button
    class="flex w-full items-start rounded border-2 px-6 py-4"
    [ngClass]="{
      'text-black':
        type === NOTIFICATION_TYPE.Success || type === NOTIFICATION_TYPE.Error,
      'bg-po-success-light': type === NOTIFICATION_TYPE.Success,
      'bg-po-info-light': type === NOTIFICATION_TYPE.Info,
      'bg-po-warning-light': type === NOTIFICATION_TYPE.Warning,
      'bg-po-error-light': type === NOTIFICATION_TYPE.Error,
      'border-po-success-base': type === NOTIFICATION_TYPE.Success,
      'border-po-info-base': type === NOTIFICATION_TYPE.Info,
      'border-po-warning-base': type === NOTIFICATION_TYPE.Warning,
      'border-po-error-base': type === NOTIFICATION_TYPE.Error
    }"
    type="button"
    (click)="onClose()"
  >
    <aw-icon
      class="h-6 w-6"
      [ngClass]="{
        'text-green-700': type === NOTIFICATION_TYPE.Success,
        'text-blue-200': type === NOTIFICATION_TYPE.Info,
        'text-yellow-500': type === NOTIFICATION_TYPE.Warning,
        'text-red-500': type === NOTIFICATION_TYPE.Error
      }"
      [icon]="icon"
    />
    <div class="flex flex-1 flex-col items-baseline pt-0.5 ltr:ml-3 rtl:mr-3">
      @if (ref.config.title) {
        <p
          class="text-body-bold text-left"
          data-testid="notificationTitle"
          data-cy="notificationTitle"
        >
          {{ ref.config.title.value | refData: ref.config.title.catalogue }}
        </p>
      }
      <p
        class="text-body-regular text-left"
        [ngClass]="ref.config.title ? 'mt-[7px]' : 'mt-0'"
        data-testid="notificationMessage"
        data-cy="notificationMessage"
      >
        {{ ref.config.message.value | refData: ref.config.message.catalogue }}
      </p>
    </div>
  </button>
</po-snackbar-container>
