import { Injectable, inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { isNil, isString } from 'lodash-es';

import { Text } from '@aw/prypco/models';
import {
  ReferenceDataCatalogue,
  ReferenceDataService,
} from '@aw/prypco/services/reference-data';

@Injectable({ providedIn: 'root' })
export class SeoService {
  private readonly titleService = inject(Title);

  private readonly metaService = inject(Meta);

  private readonly refDataService = inject(ReferenceDataService);

  readonly defaultTitle =
    'PRYPCO: About Us (Simplifying your Real Estate Journey)';

  readonly defaultDescription =
    'PRYPCO provides an ecosystem that facilitates every aspect of real estate journey from mortgage to fractional ownership and golden visa. Know more about us here!';

  setTitle(title?: string | Text): void {
    let finalTitle = isNil(title) ? this.defaultTitle : title;

    finalTitle = isString(finalTitle)
      ? finalTitle
      : this.refDataService[
          (finalTitle as Text).catalogue as ReferenceDataCatalogue
        ][(finalTitle as Text).value];

    this.titleService.setTitle(finalTitle as string);
  }

  setDescription(description?: string | Text): void {
    let finalDescription = isNil(description)
      ? this.defaultDescription
      : description;

    finalDescription = isString(finalDescription)
      ? finalDescription
      : this.refDataService[
          (finalDescription as Text).catalogue as ReferenceDataCatalogue
        ][(finalDescription as Text).value];

    this.metaService.updateTag({
      name: 'description',
      content: finalDescription as string,
    });
  }

  reset(): void {
    this.setTitle(this.defaultTitle);
    this.setDescription(this.defaultDescription);
  }
}
