import { createFeatureSelector, createSelector } from '@ngrx/store';
import { keys, pickBy } from 'lodash-es';

import * as fromSplashScreen from './splash-screen.reducer';

const selectState = createFeatureSelector<fromSplashScreen.State>(
  fromSplashScreen.featureKey,
);

export const getIsLoading = createSelector(
  selectState,
  (state: fromSplashScreen.State) =>
    keys(pickBy(state.loadingRecord)).length > 0,
);
