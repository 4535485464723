import { createFeatureSelector, createSelector } from '@ngrx/store';
import { get } from 'lodash-es';

import { uuid } from '@aw/shared/models';

import * as fromAuth from './auth.reducer';

const _getFirstName = (state: fromAuth.State) =>
  state.customer?.firstName
    ? state.customer.firstName
    : get(state, ['account', 'idTokenClaims', 'given_name'], '');

const _getLastName = (state: fromAuth.State) =>
  state.customer?.lastName
    ? state.customer.lastName
    : get(state, ['account', 'idTokenClaims', 'family_name'], '');

const selectState = createFeatureSelector<fromAuth.State>(fromAuth.featureKey);

export const selectId = createSelector(
  selectState,
  (state: fromAuth.State) => state.account?.idTokenClaims?.sub,
);

export const getFirstName = createSelector(selectState, _getFirstName);

export const getLastName = createSelector(selectState, _getLastName);

export const getFullName = createSelector(
  selectState,
  (state: fromAuth.State) => `${_getFirstName(state)} ${_getLastName(state)}`,
);

export const getJobTitle = createSelector(
  selectState,
  (state: fromAuth.State) =>
    get(state, ['account', 'idTokenClaims', 'jobTitle'], ''),
);

export const selectInitialized = createSelector(
  selectState,
  (state: fromAuth.State) => state.initialized,
);

export const selectLoading = createSelector(
  selectState,
  (state: fromAuth.State) => state.loading,
);

export const getUser = createSelector(selectState, (state: fromAuth.State) =>
  get(state, ['account', 'idTokenClaims']),
);

export const selectAccountStates = createSelector(
  selectState,
  (state: fromAuth.State) => state.accountStates,
);

export const selectLeadIdRecord = createSelector(
  selectState,
  (state: fromAuth.State) => state.leadIdRecord,
);

export const selectExternalIdentifiers = createSelector(
  selectState,
  (state: fromAuth.State) => state.externalIdentifiers,
);

export const getCustomerId = createSelector(
  selectState,
  (state: fromAuth.State) => state.customer?.id as uuid,
);
