export enum ContentType {
  OctetStream = 'application/octet-stream',
}

export enum FileExtension {
  JPG = '.jpg',
  PNG = '.png',
  PDF = '.pdf',
  WEBP = '.webp',
}

export enum HTMLNodeType {
  Element = 1,
  Text = 3,
  ProcessingInstruction = 7,
  Comment = 8,
  Document = 9,
  DocumentType = 10,
  DocumentFragment = 11,
}
