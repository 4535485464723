export const computeMonthlyPayment = (
  propertyPrice: number,
  downPayment: number,
  annualInterestRate: number,
  loanLengthInYears: number,
): number => {
  const principalInterest = propertyPrice - downPayment;
  const monthlyInterest = annualInterestRate / 100 / 12;
  const numberOfPayments = loanLengthInYears * 12;

  const partial = (1 + monthlyInterest) ** numberOfPayments;

  const monthlyPayment =
    principalInterest * ((monthlyInterest * partial) / (partial - 1));

  return parseFloat(monthlyPayment.toFixed(2));
};
