import { Direction } from '@angular/cdk/bidi';
import { ScrollStrategy } from '@angular/cdk/overlay';

export type DialogType = 'centered' | 'action';
export class DialogConfig {
  id?: string;

  panelClass?: string | string[] = '';

  hasBackdrop?: boolean = true;

  backdropClass?: string | string[] = 'aw-backdrop';

  disableClose?: boolean = false;

  width?: string = '';

  height?: string = '';

  minWidth?: number | string;

  minHeight?: number | string;

  maxWidth?: number | string = '100vw';

  maxHeight?: number | string;

  type: DialogType = 'centered';

  direction?: Direction;

  ariaDescribedBy?: string | null = null;

  ariaLabelledBy?: string | null = null;

  ariaLabel?: string | null = null;

  restoreFocus?: boolean = true;

  delayFocusTrap?: boolean = true;

  scrollStrategy?: ScrollStrategy;

  closeOnNavigation?: boolean = true;

  focusTarget: string;
}
