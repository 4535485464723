import { ISODateString, uuid } from '@aw/shared/models';

import { LeadChannel } from '@aw/prypco/enums';

export interface Lead {
  id: uuid;
  shortId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string | null;
  description: string | null;
  preferredLanguage: string;
  createdDate: ISODateString;
  createdBy: uuid | null;
  source: LeadSource;
  channel: LeadChannel;
  method: LeadMethod;
  metadata: LeadMetadata | null;
}

export interface LeadMetadata {
  platform: string;
  utmSource: string;
  utmMedium: string;
  utmCampaign: string;
  utmId: string | null;
  utmTerm: string | null;
  utmContent: string | null;
  referral: string | null;
}

export enum LeadSource {
  Prypco = 'PRYPCO',
  Salesforce = 'SALESFORCE',
  DamacAI = 'DAMAC_AI',
}

export enum LeadMethod {
  SelfOnboarding = 'SELF_ONBOARDING',
  InternalCreation = 'INTERNAL_CREATION',
}
