import { Injectable, OnDestroy, inject } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from '@microsoft/signalr';
import { Observable, Subject, from, map } from 'rxjs';

import { ENV, Environment } from '@aw/prypco/environment';

import { SocketEvent } from './enum/socket.service.enum';

interface SocketMessage {
  type: 'NEW_NOTIFICATIONS';
}

@Injectable()
export class SocketService implements OnDestroy {
  readonly message$ = new Subject<SocketMessage>();

  protected connection: HubConnection;

  private readonly env = inject(ENV) as Environment;

  private readonly msalService = inject(MsalService);

  connect(): Observable<string> {
    if (!this.connection) {
      this.connection = this.buildConnection();
    }

    if (this.connection.connectionId) {
      throw new Error(
        'The socket is already connected. This is a logic error. Disconnect from the socket before' +
          ' connecting again',
      );
    }

    this.connection.on(
      SocketEvent.MessageReceived,
      (message: SocketMessage) => {
        this.message$.next(message);
      },
    );

    let connectedUserId: string;

    this.connection.on(SocketEvent.UserConnected, (userIdentifier: string) => {
      connectedUserId = userIdentifier;
    });

    return from(this.connection.start()).pipe(map(() => connectedUserId));
  }

  ngOnDestroy() {
    if (this.connection) {
      this.connection.off(SocketEvent.MessageReceived);
      this.connection.off(SocketEvent.UserConnected);
      this.connection.stop();
    }

    this.message$.complete();
  }

  private buildConnection(): HubConnection {
    const builder = new HubConnectionBuilder();

    builder.configureLogging(LogLevel.Warning);
    // builder.withAutomaticReconnect();
    builder.withUrl(this.env.endpoints.notificationHub, {
      accessTokenFactory: () => this.getToken(),
    });

    return builder.build();
  }

  private getToken(): string {
    return this.msalService.instance.getActiveAccount()?.idToken ?? '';
  }
}
