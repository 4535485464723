import { Injectable } from '@angular/core';
import { get } from 'lodash-es';
import { map } from 'rxjs';

import { BusinessUnit } from '@aw/prypco/enums';
import { AuthFacade } from '@aw/prypco/state/auth';

@Injectable({ providedIn: 'root' })
export class DeviceService {
  readonly isApp: boolean;

  readonly appBusinessUnit?: BusinessUnit | string;

  // The footer to be hidden completely for signed in users on the blocks mobile app
  readonly hideBlocksFooter$ = this.authFacade.authenticated$.pipe(
    map(
      (authenticated) =>
        authenticated && this.appBusinessUnit === BusinessUnit.Blocks,
    ),
  );

  readonly showBlocksFooter$ = this.hideBlocksFooter$.pipe(
    map((hideFooter) => !hideFooter),
  );

  constructor(private readonly authFacade: AuthFacade) {
    this.isApp = get(window, ['prypco', 'isApp'], false);
    this.appBusinessUnit = get(
      window,
      ['prypco', 'appBusinessUnit'],
      undefined,
    );
  }
}
