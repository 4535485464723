import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { get } from 'lodash-es';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ENV, Environment } from '@aw/prypco/environment';
import { RefDataPipe } from '@aw/prypco/pipes/reference-data';
import { ReferenceDataCatalogue } from '@aw/prypco/services/reference-data';
import { SnackBarService } from '@aw/prypco/services/snackbar';

@Injectable()
export class HttpRequestErrorInterceptor implements HttpInterceptor {
  private readonly snackBarService = inject(SnackBarService);
  private readonly refDataPipe = inject(RefDataPipe);
  private readonly env = inject(ENV) as Environment;

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((event) => {
        if (event instanceof HttpErrorResponse) {
          const errorCode = get(
            event,
            ['error', 'ErrorCode'],
            'SHOULD_NOT_EXIST',
          );

          const translation = this.refDataPipe.transform(
            errorCode,
            ReferenceDataCatalogue.Error,
          );

          if (this.env.featureFlags.devErrorInterceptor) {
            this.snackBarService.error(translation);
          } else if (translation !== errorCode) {
            (event as any).isHandled = true;
            this.snackBarService.error(translation);
          }
        }

        return throwError(event);
      }),
    );
  }
}
