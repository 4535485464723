<aside
  #aside
  class="flex h-full w-full flex-1 overflow-y-auto bg-transparent"
  [ngClass]="
    config.type === 'centered' ? 'items-center justify-center' : 'justify-end'
  "
  data-testid="container"
  tabindex="-1"
  aria-modal="true"
  role="alertdialog"
  [@dialogContainer]="animationState"
  [attr.id]="id"
  (@dialogContainer.start)="onAnimationStart($event)"
  (@dialogContainer.done)="onAnimationDone($event)"
  (click)="onClick($event)"
>
  <ng-template cdkPortalOutlet />
</aside>
