import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SocketService } from './services/socket/socket.service';
import { SocketEffects } from './socket.effects';
import { SocketFacade } from './socket.facade';
import * as fromSocket from './socket.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(fromSocket.featureKey, fromSocket.reducer),
    EffectsModule.forFeature([SocketEffects]),
  ],
  providers: [SocketService, SocketFacade],
})
export class SocketStateModule {}
