import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isString } from 'lodash-es';
import { Observable } from 'rxjs';
import { NIL } from 'uuid';

import { PatchOp, uuid } from '@aw/shared/models';
import { composeQueryParams } from '@aw/shared/utilities';

import { BusinessUnit, JobTitle } from '@aw/prypco/enums';
import { prypcoEnv } from '@aw/prypco/environment';
import { DictionaryItem } from '@aw/prypco/services/lead';

import { AccountState } from './enums/account-state.enum';
import { AddNewMemberPayload } from './models/payloads/add-new-member-payload.model';
import { AgentUpdatePayload } from './models/payloads/agent-update-payload.model';
import { SetAccountStatePayload } from './models/payloads/set-account-state-payload.model';
import { BrokersResponse } from './models/responses/brokers-response.model';
import { BackOfficeMemberCreateResponse } from './models/responses/member-create-response.model';
import { SalesAgentsResponse } from './models/responses/sales-agents-response.model';
import { UserInfoResponse } from './models/responses/user-info-response.model';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private readonly httpClient: HttpClient) {
    this.baseUrl = prypcoEnv.endpoints.user;
    this.userBaseUrl = prypcoEnv.endpoints.userBaseApi;
    this.dictionaryBaseUrl = prypcoEnv.endpoints.dictionaries;
    this.leadBaseUrl = prypcoEnv.endpoints.leadBase;
  }

  private readonly baseUrl: string;

  private readonly userBaseUrl: string;

  private readonly dictionaryBaseUrl: string;

  private readonly leadBaseUrl: string;

  delete(userId: uuid): Observable<void> {
    return this.httpClient.delete<void>(`${this.baseUrl}/${userId}`);
  }

  updateAgent(userId: uuid, payload: AgentUpdatePayload): Observable<void> {
    const ops: Array<PatchOp> = [
      new PatchOp('/phoneNumber', 'replace', payload.phoneNumber),
    ];

    if (payload.agencyId) {
      ops.push(new PatchOp('/agencyId', 'replace', payload.agencyId));
    }

    return this.httpClient.patch<void>(
      `${this.userBaseUrl}/User/${userId}`,
      ops,
    );
  }

  getUsers(role: JobTitle): Observable<Array<DictionaryItem>> {
    const params = composeQueryParams({
      role,
    });

    return this.httpClient.get<Array<DictionaryItem>>(
      `${this.dictionaryBaseUrl}/Users`,
      {
        params,
      },
    );
  }

  getSalesAgents(agencyId?: uuid | null): Observable<SalesAgentsResponse> {
    let params: HttpParams | undefined = undefined;
    const coercedAgencyId = this.coerceAgencyId(agencyId);

    if (coercedAgencyId !== undefined) {
      params = composeQueryParams({
        filterByAgency: true,
        agencyId: coercedAgencyId,
      });
    }

    return this.httpClient.get<SalesAgentsResponse>(
      `${this.leadBaseUrl}/SalesAgencies/users`,
      {
        params,
      },
    );
  }

  getBrokers(brokerageId?: uuid | null): Observable<BrokersResponse> {
    let params: HttpParams | undefined = undefined;
    const coercedBrokerageId = this.coerceAgencyId(brokerageId);
    if (coercedBrokerageId !== undefined) {
      params = composeQueryParams({
        filterByBrokerage: true,
        brokerageId: coercedBrokerageId,
      });
    }

    return this.httpClient.get<BrokersResponse>(`${this.leadBaseUrl}/Brokers`, {
      params,
    });
  }

  getInfo(): Observable<UserInfoResponse> {
    return this.httpClient.get<UserInfoResponse>(
      `${this.userBaseUrl}/User/Information`,
    );
  }

  setAccountState(
    userId: uuid,
    payload: SetAccountStatePayload,
  ): Observable<void> {
    return this.httpClient.post<void>(`${this.userBaseUrl}/AccountStates`, {
      userId: userId.trim(),
      businessUnit: payload.businessUnit,
      key: payload.accountState,
      value: payload.value || false,
    });
  }

  addAcknowledgementState(
    businessUnit: BusinessUnit,
    accountState: AccountState,
  ): Observable<void> {
    return this.httpClient.post<void>(
      `${this.userBaseUrl}/AccountStates/acknowledge`,
      {
        businessUnit,
        key: accountState,
      },
    );
  }

  addNewMember(payload: AddNewMemberPayload) {
    return this.httpClient.post<BackOfficeMemberCreateResponse>(
      `${this.userBaseUrl}/User/Backoffice`,
      payload,
    );
  }

  private coerceAgencyId(agencyId?: uuid | null): string | undefined {
    if (agencyId === null || agencyId === NIL) {
      return '';
    }

    if (isString(agencyId)) {
      return agencyId;
    }

    return undefined;
  }
}
