import { Injectable, Injector } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { get } from 'lodash-es';
import { Observable, firstValueFrom } from 'rxjs';

// Run guards in a sequence instead of parallel
@Injectable({
  providedIn: 'root',
})
export class SequenceGuard implements CanActivate, CanLoad {
  constructor(private injector: Injector) {}

  async canLoad(
    route: Route,
    segments: UrlSegment[],
  ): Promise<boolean | UrlTree> {
    const guards = get(route, ['data', 'guards'], []);

    for (const guard of guards) {
      const instance: CanLoad = this.injector.get<CanLoad>(guard);

      let result = instance.canLoad(route, segments);

      // Depending on the route result, we may need to await upon it in different ways.
      if (result instanceof Promise) {
        // eslint-disable-next-line no-await-in-loop
        result = await result;
      }

      if (result instanceof Observable) {
        // eslint-disable-next-line no-await-in-loop
        result = await firstValueFrom(result);
      }

      if (result === false || result instanceof UrlTree) {
        return result;
      }
    }

    return true;
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean | UrlTree> {
    const guards = route.data['guards'] || [];

    for (const guard of guards) {
      const instance: CanActivate = this.injector.get<CanActivate>(guard);

      let result = instance.canActivate(route, state);

      // Depending on the route result, we may need to await upon it in different ways.
      if (result instanceof Promise) {
        // eslint-disable-next-line no-await-in-loop
        result = await result;
      }

      if (result instanceof Observable) {
        // eslint-disable-next-line no-await-in-loop
        result = await firstValueFrom(result);
      }

      if (result === false || result instanceof UrlTree) {
        return result;
      }
    }

    return true;
  }
}
