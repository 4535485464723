import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { NgModule } from '@angular/core';

import { SnackBarComponent } from './components/snackbar/snackbar.component';
import { SnackBarService } from './snackbar.service';

@NgModule({
  imports: [OverlayModule, PortalModule, SnackBarComponent],
  exports: [SnackBarComponent],
  providers: [SnackBarService],
})
export class SnackBarModule {}
