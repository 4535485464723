import { Action, createReducer, on } from '@ngrx/store';

import * as EventActions from './event.actions';
import { GlobalEvent } from './models/global-event.model';

export const featureKey = 'event';

export interface EventPartialState {
  readonly [featureKey]: State;
}

export interface State {
  event?: GlobalEvent;
}

export const initialState: State = {
  event: undefined,
};

const eventReducer = createReducer(
  initialState,
  on(EventActions.dispatch, (state, { event, payload }) => ({
    ...state,
    event: { event, payload },
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return eventReducer(state, action);
}
