import * as amplitude from '@amplitude/analytics-browser';
import { Injectable, inject } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

import { JobTitle } from '@aw/prypco/enums';
import { ENV, Environment } from '@aw/prypco/environment';

import { AmplitudeService } from './amplitude.abstract';
import { AmplitudeTrackEvent } from './model/amplitude-event.model';
import {
  AmplitudeUserProperty,
  AmplitudeUserPropertyList,
} from './model/amplitude-user.model';

@UntilDestroy()
@Injectable()
export class AmplitudeServiceImpl extends AmplitudeService {
  private readonly env = inject(ENV) as Environment;
  private _shouldTrack = true;

  constructor() {
    super();
    const { apiKey, options } = this.env.externalScripts.amplitude;
    amplitude.init(apiKey, options);
  }

  track(trackEvent: AmplitudeTrackEvent): void {
    if (this._shouldTrack) {
      amplitude.track(trackEvent.eventInput, trackEvent.eventProperties);
    }

    if (this.env.externalScripts.amplitude.debug) {
      // eslint-disable-next-line no-restricted-syntax
      console.debug(trackEvent);
    }
  }

  setUserProperty(propList: AmplitudeUserPropertyList): void {
    if (this._shouldTrack) {
      const identifyEvent = new amplitude.Identify();

      propList.forEach((userProp: AmplitudeUserProperty) =>
        identifyEvent.set(userProp.eventProperty, userProp.eventValue),
      );

      amplitude.identify(identifyEvent);
    }

    if (this.env.externalScripts.amplitude.debug) {
      // eslint-disable-next-line no-restricted-syntax
      console.debug(propList);
    }
  }

  set shouldTrack(value: boolean) {
    this._shouldTrack = value;
    amplitude.setOptOut(!value);
  }

  get shouldTrack(): boolean {
    return this._shouldTrack;
  }

  get deviceId(): string | undefined {
    return amplitude.getDeviceId();
  }

  set userId(userId: string) {
    amplitude.setUserId(userId);
  }
}
