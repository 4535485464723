import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromEvent from './event.reducer';

const selectState = createFeatureSelector<fromEvent.State>(
  fromEvent.featureKey,
);

export const getEvent = createSelector(
  selectState,
  (state: fromEvent.State) => state.event,
);
