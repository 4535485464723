import { uuid } from '@aw/shared/models';

import { PersonalDocCategory, PropertyDocCategory } from '@aw/prypco/enums';

export class MortgageApplicationDocument {
  constructor(
    readonly documentName: string,
    readonly category: PersonalDocCategory | PropertyDocCategory,
    readonly size = 0,
    readonly applicantId: uuid | null = '',
    readonly applicationFormId: uuid = '',
    readonly createdBy: uuid | null = '',
  ) {}
}
