/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, filter, map, of, withLatestFrom } from 'rxjs';

import { ENV, Environment } from '@aw/prypco/environment';
import { AuthFacade } from '@aw/prypco/state/auth';

import { SocketService } from './services/socket/socket.service';
import * as SocketActions from './socket.actions';
import { SocketFacade } from './socket.facade';

@Injectable()
export class SocketEffects {
  private readonly env = inject(ENV) as Environment;

  private readonly actions$ = inject(Actions);

  private readonly socketService = inject(SocketService);

  private readonly authFacade = inject(AuthFacade);

  private readonly socketFacade = inject(SocketFacade);

  readonly triggerConnection$ = createEffect(() =>
    this.authFacade.authenticated$.pipe(
      filter(() => this.env.featureFlags.notifications),
      withLatestFrom(
        this.socketFacade.isConnected$,
        this.socketFacade.isConnecting$,
      ),
      filter(
        ([authenticated, isConnected, isConnecting]) =>
          authenticated && !isConnected && !isConnecting,
      ),
      map(() => SocketActions.connect()),
    ),
  );

  readonly connect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SocketActions.connect),
      exhaustMap(() =>
        this.socketService.connect().pipe(
          map((userIdentifier) => SocketActions.connected({ userIdentifier })),
          catchError(() => of(SocketActions.connectionFailure())),
        ),
      ),
    ),
  );
}
