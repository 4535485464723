// The portal is implicitly '' so it doesn't have an entry here
export enum BlocksModule {
  Onboarding = 'onboarding',
  BackOffice = 'back-office',
}

export enum BlocksPage {
  TermsAndConditions = 'terms-and-conditions',
  KeyRisks = 'key-risks',
  AppLanding = 'app-landing',
}
