import { AmplitudeTrackEvent } from './model/amplitude-event.model';
import { AmplitudeUserPropertyList } from './model/amplitude-user.model';

/** Define tracking events based on requirements */
export abstract class AmplitudeService {
  abstract track(amplitudeTrackEvent: AmplitudeTrackEvent): void;

  abstract setUserProperty(propList: AmplitudeUserPropertyList): void;

  abstract set shouldTrack(value: boolean);

  abstract get shouldTrack(): boolean;

  abstract get deviceId(): string | undefined;

  abstract set userId(userId: string | undefined);
}
