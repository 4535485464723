export enum Country {
  Afghanistan = 'AFG',
  AlandIslands = 'ALA',
  Albania = 'ALB',
  Algeria = 'DZA',
  AmericanSamoa = 'ASM',
  Andorra = 'AND',
  Angola = 'AGO',
  Anguilla = 'AIA',
  Antarctica = 'ATA',
  AntiguaAndBarbuda = 'ATG',
  Argentina = 'ARG',
  Armenia = 'ARM',
  Aruba = 'ABW',
  Australia = 'AUS',
  Austria = 'AUT',
  Azerbaijan = 'AZE',
  Bahamas = 'BHS',
  Bahrain = 'BHR',
  Bangladesh = 'BGD',
  Barbados = 'BRB',
  Belarus = 'BLR',
  Belgium = 'BEL',
  Belize = 'BLZ',
  Benin = 'BEN',
  Bermuda = 'BMU',
  Bhutan = 'BTN',
  Bolivia = 'BOL',
  BosniaAndHerzegovina = 'BIH',
  Botswana = 'BWA',
  BouvetIsland = 'BVT',
  Brazil = 'BRA',
  BritishIndianOceanTerritory = 'IOT',
  BruneiDarussalam = 'BRN',
  Bulgaria = 'BGR',
  BurkinaFaso = 'BFA',
  Burundi = 'BDI',
  Cambodia = 'KHM',
  Cameroon = 'CMR',
  Canada = 'CAN',
  CapeVerde = 'CPV',
  CaymanIslands = 'CYM',
  CentralAfricanRepublic = 'CAF',
  Chad = 'TCD',
  Chile = 'CHL',
  China = 'CHN',
  ChristmasIsland = 'CXR',
  CocosKeelingIslands = 'CCK',
  Colombia = 'COL',
  Comoros = 'COM',
  Congo = 'COG',
  CongoDemocraticRepublic = 'COD',
  CookIslands = 'COK',
  CostaRica = 'CRI',
  CoteDIvoire = 'CIV',
  Croatia = 'HRV',
  Cuba = 'CUB',
  Cyprus = 'CYP',
  CzechRepublic = 'CZE',
  Denmark = 'DNK',
  Djibouti = 'DJI',
  Dominica = 'DMA',
  DominicanRepublic = 'DOM',
  Ecuador = 'ECU',
  Egypt = 'EGY',
  ElSalvador = 'SLV',
  EquatorialGuinea = 'GNQ',
  Eritrea = 'ERI',
  Estonia = 'EST',
  Ethiopia = 'ETH',
  FalklandIslands = 'FLK',
  FaroeIslands = 'FRO',
  Fiji = 'FJI',
  Finland = 'FIN',
  France = 'FRA',
  FrenchGuiana = 'GUF',
  FrenchPolynesia = 'PYF',
  FrenchSouthernTerritories = 'ATF',
  Gabon = 'GAB',
  Gambia = 'GMB',
  Georgia = 'GEO',
  Germany = 'DEU',
  Ghana = 'GHA',
  Gibraltar = 'GIB',
  Greece = 'GRC',
  Greenland = 'GRL',
  Grenada = 'GRD',
  Guadeloupe = 'GLP',
  Guam = 'GUM',
  Guatemala = 'GTM',
  Guernsey = 'GGY',
  Guinea = 'GIN',
  GuineaBissau = 'GNB',
  Guyana = 'GUY',
  Haiti = 'HTI',
  HeardIslandMcdonaldIslands = 'HMD',
  Vatican = 'VAT',
  Honduras = 'HND',
  HongKong = 'HKG',
  Hungary = 'HUN',
  Iceland = 'ISL',
  India = 'IND',
  Indonesia = 'IDN',
  Iran = 'IRN',
  Iraq = 'IRQ',
  Ireland = 'IRL',
  IsleOfMan = 'IMN',
  Israel = 'ISR',
  Italy = 'ITA',
  Jamaica = 'JAM',
  Japan = 'JPN',
  Jersey = 'JEY',
  Jordan = 'JOR',
  Kazakhstan = 'KAZ',
  Kenya = 'KEN',
  Kiribati = 'KIR',
  SouthKorea = 'KOR',
  NorthKorea = 'PRK',
  Kuwait = 'KWT',
  Kyrgyzstan = 'KGZ',
  Laos = 'LAO',
  Latvia = 'LVA',
  Lebanon = 'LBN',
  Lesotho = 'LSO',
  Liberia = 'LBR',
  Libya = 'LBY',
  Liechtenstein = 'LIE',
  Lithuania = 'LTU',
  Luxembourg = 'LUX',
  Macao = 'MAC',
  Madagascar = 'MDG',
  Malawi = 'MWI',
  Malaysia = 'MYS',
  Maldives = 'MDV',
  Mali = 'MLI',
  Malta = 'MLT',
  MarshallIslands = 'MHL',
  Martinique = 'MTQ',
  Mauritania = 'MRT',
  Mauritius = 'MUS',
  Mayotte = 'MYT',
  Mexico = 'MEX',
  Micronesia = 'FSM',
  Moldova = 'MDA',
  Monaco = 'MCO',
  Mongolia = 'MNG',
  Montenegro = 'MNE',
  Montserrat = 'MSR',
  Morocco = 'MAR',
  Mozambique = 'MOZ',
  Myanmar = 'MMR',
  Namibia = 'NAM',
  Nauru = 'NRU',
  Nepal = 'NPL',
  Netherlands = 'NLD',
  NewCaledonia = 'NCL',
  NewZealand = 'NZL',
  Nicaragua = 'NIC',
  Niger = 'NER',
  Nigeria = 'NGA',
  Niue = 'NIU',
  NorfolkIsland = 'NFK',
  NorthernMarianaIslands = 'MNP',
  Norway = 'NOR',
  Oman = 'OMN',
  Pakistan = 'PAK',
  Palau = 'PLW',
  Palestine = 'PSE',
  Panama = 'PAN',
  PapuaNewGuinea = 'PNG',
  Paraguay = 'PRY',
  Peru = 'PER',
  Philippines = 'PHL',
  Pitcairn = 'PCN',
  Poland = 'POL',
  Portugal = 'PRT',
  PuertoRico = 'PRI',
  Qatar = 'QAT',
  NorthMacedonia = 'MKD',
  Reunion = 'REU',
  Romania = 'ROU',
  Russia = 'RUS',
  Rwanda = 'RWA',
  SaintBarthelemy = 'BLM',
  SaintHelena = 'SHN',
  SaintKittsAndNevis = 'KNA',
  SaintLucia = 'LCA',
  SaintMartin = 'MAF',
  SaintPierreAndMiquelon = 'SPM',
  SaintVincentAndGrenadines = 'VCT',
  Samoa = 'WSM',
  SanMarino = 'SMR',
  SaoTomeAndPrincipe = 'STP',
  SaudiArabia = 'SAU',
  Senegal = 'SEN',
  Serbia = 'SRB',
  Seychelles = 'SYC',
  SierraLeone = 'SLE',
  Singapore = 'SGP',
  SintMaarten = 'SXM',
  Slovakia = 'SVK',
  Slovenia = 'SVN',
  SolomonIslands = 'SLB',
  Somalia = 'SOM',
  SouthAfrica = 'ZAF',
  SouthGeorgiaAndSandwichIsland = 'SGS',
  SouthSudan = 'SSD',
  Spain = 'ESP',
  SriLanka = 'LKA',
  Sudan = 'SDN',
  Suriname = 'SUR',
  Svalbard = 'SJM',
  Swaziland = 'SWZ',
  Sweden = 'SWE',
  Switzerland = 'CHE',
  Syria = 'SYR',
  Taiwan = 'TWN',
  Tajikistan = 'TJK',
  Tanzania = 'TZA',
  Thailand = 'THA',
  TimorLeste = 'TLS',
  Togo = 'TGO',
  Tokelau = 'TKL',
  Tonga = 'TON',
  TrinidadAndTobago = 'TTO',
  Tunisia = 'TUN',
  Turkey = 'TUR',
  Turkmenistan = 'TKM',
  TurksAndCaicosIslands = 'TCA',
  Tuvalu = 'TUV',
  Uganda = 'UGA',
  Ukraine = 'UKR',
  UnitedArabEmirates = 'ARE',
  UnitedKingdom = 'GBR',
  UnitedStatesOutlyingIslands = 'UMI',
  UnitedStates = 'USA',
  Uruguay = 'URY',
  Uzbekistan = 'UZB',
  Vanuatu = 'VUT',
  Venezuela = 'VEN',
  Vietnam = 'VNM',
  VirginIslandsBritish = 'VGB',
  VirginIslandsUS = 'VIR',
  WallisAndFutuna = 'WLF',
  WesternSahara = 'ESH',
  Yemen = 'YEM',
  Zambia = 'ZMB',
  Zimbabwe = 'ZWE',
}
