export enum Module {
  BackOffice = 'back-office',
  Services = 'services',
  Onboarding = 'onboarding',
  Portal = 'portal',
  KnowledgeHub = 'knowledge-hub',
  Blocks = 'blocks',
  Mortgage = 'mortgage',
  Listing = 'listing',
  Dashboard = 'dashboard',
  MortgageApplication = 'application',
  AuthRedirect = 'auth-redirect',
  Dev = 'dev',
  Profile = 'profile',
}

export enum Page {
  Application = 'application',
  Applications = 'applications',
  IncomeAndLiabilities = 'income-liabilities',
  PropertyAndMortgage = 'property-mortgage',
  UploadPersonalDocuments = 'upload-personal-docs',
  SelectLoanOption = 'select-loan-option',
  UploadPropertyDocuments = 'upload-property-docs',
  OfferLetterPreview = 'offer-letter-preview',
  BookOfferLetterAppointment = 'book-offer-letter-appointment',
  UploadSignedOfferLetter = 'upload-signed-offer-letter',
  UploadPreapproval = 'upload-preapproval',
  ComingSoon = '',
  Home = '',
  Signup = 'signup',
  Mortgage = 'mortgage',
  Calculator = 'calculator',
  Blocks = 'blocks',
  Blog = 'blog',
  Faq = 'faq',
  Glossary = 'glossary',
  Property = 'property',
  Options = '',
  Journey = 'journey',
  Register = 'register',
  Success = 'success',
  Profile = 'profile',
  MortgageProfile = 'profile',
  MortgageIncomeAndLiabilities = 'income-liabilities',
  MortgageProperty = 'property',
  AdditionalInformation = 'additional-information',
  MortgageGetApproved = 'get-approved',
  Dashboard = 'dashboard',
  PropertyListing = 'properties',
  PropertyDetails = 'property-details',
  ResetPassword = 'reset-password',
  Checkout = 'checkout',
  SignIn = 'sign-in',
  SignUpSuccess = 'sign-up-success',
  OurStory = 'our-story',
  AgentAppMember = 'agent-app-member',
}

export enum RouteParam {
  LeadId = 'leadId',
  MortgageApplicationId = 'mortgageApplicationId',
  PropertyId = 'propertyId',
  AgencyId = 'agencyId',
  BankOfferId = 'bankOfferId',
  MemberId = 'memberId',
  BrokerageId = 'brokerageId',
}

export enum GuestPage {
  Calculator = 'calculator',
}

export enum QueryParam {
  SearchKeyword = 'searchKeyword',
  Skip = 'skip',
  Take = 'take',
  OrderBy = 'orderBy',
  Sort = 'sort',
  Stage = 'stage',
  Status = 'statusList',
  Landscape = 'landscape',
  AgencyId = 'agencyId',
  AgencyList = 'agencyList',
  AgentList = 'agentList',
  AdvisorList = 'advisorList',
  From = 'from',
  To = 'to',
  PreferredLanguage = 'preferredLanguage',
  Type = 'type',
  TypeList = 'typeList',
  TransactionType = 'transactionType',
  TelesalesList = 'telesalesList',
  BrokerageList = 'brokerageList',
  RedirectBusinessUnit = 'rbu',
}
