export enum VacancyStatus {
  Rented = 'RENTED',
  Vacant  = 'VACANT',
}

export enum PropertyStatus {
  Upcoming = 'UPCOMING',
  Funded = 'FUNDED',
  Available = 'AVAILABLE',
  Exited = 'EXITED',
}

export enum EmploymentStatus {
  SelfEmployed = 'SELF_EMPLOYED',
  Salaried = 'SALARIED',
  Homemaker = 'HOUSEWIFE',
  Retired = 'RETIRED',
  Student = 'STUDENT',
}

export enum BusinessType {
  LLC = 'LLC',
}

export enum PropertyDocumentType {
  InvestmentMemorandum = 'INVESTMENT_MEMORANDUM',
  PropertyValuationReport = 'PROPERTY_VALUATION_REPORT',
  Projections = 'PROJECTIONS',
}

export enum PropertyInvestmentDividendType {
  Upcoming = 'UPCOMING',
}

export enum TransactionStatus {
  Pending = 'PENDING',
  Refused = 'REFUSED',
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  Void = 'VOID',
  VerificationInProgress = 'VERIFICATION_IN_PROGRESS',
}

export enum TransactionType {
  TopUp = 'DEPOSIT',
  Withdrawal = 'WITHDRAWAL',
  Refund = 'REFUND',
  Payment = 'PAYMENT',
  DividendPayment = 'DIVIDEND_PAYMENT',
}

export enum TransactionInstrument {
  BankTransfer = 'BANK_TRANSFER',
  Card = 'CARD',
  Wallet = 'WALLET',
  DirectDebit = 'DIRECT_DEBIT',
}

export enum BlocksLeadAddressDocumentType {
  Ejari = 'BLOCKS_LEAD_EJARI',
  UtilityBill = 'BLOCKS_LEAD_UTILITY_TELECOM_BILL',
  BankStatement = 'BLOCKS_LEAD_BANK_STATEMENT',
}

export enum CustomerDocument {
  OnboardingDocuments = 'CUSTOMER_DOCUMENTS',
}

export enum TransactionDocument {
  ProofOfTransfer = 'TRANSACTION_PROOF_OF_TRANSFER',
}

export enum TransactionActions {
  Approved = 'APPROVED',
  Refused = 'REFUSED',
  VerificationInProgress = 'VERIFICATION_IN_PROGRESS',
}

export enum PaymentSourceStatus {
  AwaitingBeneficiaryCoolOff = 'AWAITING_BENEFICIARY_COOL_OFF',
  Active = 'ACTIVE',
}

export enum TransactionCancellationReason {
  CustomerRequest = 'CUSTOMER_REQUEST',
}
