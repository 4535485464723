import { FocusOrigin } from '@angular/cdk/a11y';
import { hasModifierKey } from '@angular/cdk/keycodes';
import { GlobalPositionStrategy, OverlayRef } from '@angular/cdk/overlay';
import { Observable, Subject, filter, take, takeUntil } from 'rxjs';

import { DialogContainerComponent } from '../components/dialog-container/dialog-container.component';
import { DialogState } from '../enums/dialog-service.enums';
import { DialogType } from '../models/dialog-config.model';

export class DialogRef<T, R = any> {
  componentInstance: T;

  get disableClose(): boolean {
    return this.container.config.disableClose || false;
  }

  get closed(): Observable<T | undefined> {
    return this.closed$.asObservable();
  }

  constructor(
    readonly id: string | undefined,
    readonly container: DialogContainerComponent,
    private readonly overlayRef: OverlayRef,
  ) {
    container.animationStateChange$
      .pipe(
        filter((event) => event.state === DialogState.Closed),
        take(1),
      )
      .subscribe(() => {
        clearTimeout(this.closeFallBackTimeout);

        this.finishDialogClose();
      });

    this.initOverlayDetachmentsHandler(this.overlayRef);
    this.initCloseHandlers(this.overlayRef);
  }

  private result: T | undefined;

  private closeFallBackTimeout: any;

  private dialogState = DialogState.Open;

  private readonly destroyed$ = new Subject<void>();

  private readonly closed$ = new Subject<T | undefined>();

  close(
    dialogResult?: any | undefined,
    closeInteractionType: FocusOrigin = 'mouse',
  ): void {
    this.result = dialogResult;
    this.container.closeInteractionType = closeInteractionType;

    this.dialogState = DialogState.Closing;
    this.container.startExitAnimation();

    this.container.animationStateChange$
      .pipe(
        filter(({ state }) => state === DialogState.Closing),
        take(1),
        takeUntil(this.destroyed$),
      )
      .subscribe((event) => {
        this.overlayRef.detachBackdrop();

        this.closeFallBackTimeout = setTimeout(
          () => this.finishDialogClose(),
          event.totalTime + 100,
        );
      });
  }

  updatePosition(type?: DialogType): void {
    // const strategy = this._getPositionStrategy();
    // if (type === 'centered') {
    //   strategy.centerHorizontally();
    //   strategy.centerVertically();
    // } else {
    //   strategy.right('0px');
    //   strategy.top('0px');
    //   this.overlayRef.updateSize({ height: '100%' });
    // }
    // this.overlayRef.updatePosition();
  }

  updateSize(width = '', height = ''): void {
    this.overlayRef.updateSize({ width, height });
    this.overlayRef.updatePosition();
  }

  addPanelClass(classes: string | string[]): void {
    this.overlayRef.addPanelClass(classes);
  }

  removePanelClass(classes: string | string[]): void {
    this.overlayRef.removePanelClass(classes);
  }

  getState(): DialogState {
    return this.dialogState;
  }

  private initOverlayDetachmentsHandler(overlayRef: OverlayRef): void {
    overlayRef
      .detachments()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.closed$.next(this.result);
        this.closed$.complete();
        this.componentInstance = null as any;
        this.overlayRef.dispose();

        this.destroyed$.next();
        this.destroyed$.complete();
      });
  }

  private initCloseHandlers(overlayRef: OverlayRef): void {
    overlayRef
      .keydownEvents()
      .pipe(
        filter(
          (event) =>
            event.key === 'Escape' &&
            !this.disableClose &&
            !hasModifierKey(event),
        ),
        takeUntil(this.destroyed$),
      )
      .subscribe((event) => {
        event.preventDefault();
        this.close(this.result, 'keyboard');
      });

    overlayRef
      .backdropClick()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        if (this.disableClose) {
          this.container.recaptureFocus();
        } else {
          this.close(this.result, 'mouse');
        }
      });
  }

  private finishDialogClose() {
    this.dialogState = DialogState.Closed;
    this.overlayRef.dispose();
  }

  /** Fetches the position strategy object from the overlay ref. */
  private _getPositionStrategy(): GlobalPositionStrategy {
    return this.overlayRef.getConfig()
      .positionStrategy as GlobalPositionStrategy;
  }
}
