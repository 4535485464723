import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { RefDataPipe } from '@aw/prypco/pipes/reference-data';

import { HttpRequestErrorInterceptor } from './error.interceptor';
import { PatchHeaderInterceptor } from './patch-header.interceptor';

@NgModule({
  imports: [RefDataPipe],
  providers: [
    {
      provide: RefDataPipe,
      useClass: RefDataPipe,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PatchHeaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestErrorInterceptor,
      multi: true,
    },
  ],
})
export class InterceptorsModule {}
