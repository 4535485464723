import { Action, createReducer, on } from '@ngrx/store';

import * as SplashScreenActions from './splash-screen.actions';

export const featureKey = 'splash-screen';

export interface AuthPartialState {
  readonly [featureKey]: State;
}

export interface State {
  loadingRecord: Record<string, boolean>;
}

export const initialState: State = {
  loadingRecord: {},
};

export const updateLoadingRecord = (
  loadingRecord: Record<string, boolean>,
  key: string,
  isLoading: boolean,
): Record<string, boolean> => {
  const clonedRecord = { ...loadingRecord };

  if (isLoading) {
    clonedRecord[key] = true;
  } else {
    delete clonedRecord[key];
  }

  return clonedRecord;
};

const authReducer = createReducer(
  initialState,
  on(SplashScreenActions.setLoading, (state, { key, isLoading }) => ({
    ...state,
    loadingRecord: updateLoadingRecord(state.loadingRecord, key, isLoading),
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return authReducer(state, action);
}
