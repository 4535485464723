import { compact } from 'lodash-es';

import { NavSegments } from '@aw/shared/models';

export class RoutingHistoryEntry {
  readonly segments: NavSegments;

  readonly queryParams: Record<string, string>;

  readonly url: string;

  constructor(private readonly _partialUrl: string) {
    const completeUrl = `${document.location.protocol}//${document.location.host}${this._partialUrl}`;

    this.url = _partialUrl;
    this._url = new URL(completeUrl);
    this.segments = this.composeNavSegments(this._url);
    this.queryParams = this.composeQueryParams(this._url);
  }

  private readonly _url: URL;

  private readonly composeNavSegments = (url: URL): NavSegments => {
    const split = compact(url.pathname.split('/'));

    return [`/${split[0]}`, ...split.slice(1)];
  };

  private readonly composeQueryParams = (url: URL): Record<string, string> => {
    const params = url.searchParams.toString();
    const split = params.split('&');

    return split.reduce(
      (acc: Record<string, string>, param: string) => {
        const split = param.split('=');
        const key = split[0];
        const value = split[split.length - 1];

        acc[key] = decodeURIComponent(value.replace(/\+/g, ' '));

        return acc;
      },
      {} as Record<string, string>,
    );
  };
}
