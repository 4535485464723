export enum Amenity {
  Gym = 'GYM',
  Pool = 'POOL',
  Balcony = 'BALCONY',
  OpenPlanKitchen = 'OPEN_PLAN_KITCHEN',
  BillIncluded = 'BILL_INCLUDED',
  CoveredParking = 'COVERED_PARKING',
  PlayingArea = 'PLAYING_AREA',
  PartyHall = 'PARTY_HALL',
  Concierge247 = '24_7_CONCIERGE',
  Jacuzzi = 'JACUZZI',
  KitchenAppliance = 'KITCHEN_APPLIANCE',
  BbqArea = 'BBQ_AREA',
  LandscapeGarden = 'LANDSCAPE_GARDEN',
  Security = 'SECURITY',
  AirConditioning = 'CENTRAL_AIR_CONDITIONING',
  BuildInWardrobe = 'BUILT_IN_WARDROBE',
  CommunityView = 'COMMUNITY_VIEW',
  BurjKhalifaView = 'BURJ_KHALIFA_VIEW',
  GolfCourseView = 'VIEW_OF_GOLF_COURSE',
  CanalView = 'CANAL_VIEW',
  NearMetro = 'NEAR_METRO',
  NearSchool = 'NEAR_SCHOOL',
  NearMall = 'NEAR_MALL',
  NearHospital = 'NEAR_HOSPITAL',
  OnHighFloor = 'ON_HIGH_FLOOR',
  OnMidFloor = 'ON_MID_FLOOR',
  OnLowFloor = 'ON_LOW_FLOOR',
  PetsAllowed = 'PETS_ALLOWED',
  SwimmingPool = 'SWIMMING_POOL',
  StudyRoom = 'STUDY_ROOM',
  DriversRoom = 'DRIVER_ROOM',
  MaidsRoom = 'MAIDS_ROOM',
  CommunityCenter = 'COMMUNITY_CENTER',
  HealthClub = 'HEALTH_CLUB',
  Supermarket = 'SUPERMARKET',
  Restaurant = 'RESTAURANT',
  Spa = 'SPA',
}

export enum RoomType {
  Bathroom = 'BATHROOM',
  Bedroom = 'BEDROOM',
  Kitchen = 'KITCHEN',
  LivingRoom = 'LIVING_ROOM',
  MaidRoom = 'MAID_ROOM',
  Office = 'OFFICE',
  DriversRoom = 'DRIVER_ROOM',
}

export enum PropertyType {
  Apartment = 'APARTMENT',
  HotelApartment = 'HOTEL_APARTMENT',
  House = 'HOUSE',
  Villa = 'VILLA',
  Hotel = 'HOTEL',
  Penthouse = 'PENTHOUSE',
  Townhouse = 'TOWNHOUSE',
}

export enum FurnishingState {
  Furnished = 'FURNISHED',
  Unfurnished = 'UNFURNISHED',
  SemiFurnished = 'SEMI_FURNISHED',
}

export enum RentalType {
  HolidayHome = 'HOLIDAY_HOME',
  ShortLease = 'SHORT_LEASE',
  LongLease = 'LONG_LEASE',
}
