import {
  find,
  get,
  isEmpty,
  isFunction,
  isNil,
  isObject,
  keys,
  some,
} from 'lodash-es';

import { PatchOp } from '@aw/shared/models';

import {
  deepClone,
  hasOwnProperty,
  isPrimitive,
} from '../general/general.util';

const escapePathComponent = (path: string): string => {
  if (path.indexOf('/') === -1 && path.indexOf('~') === -1) {
    return path;
  }

  return path.replace(/~/g, '~0').replace(/\//g, '~1');
};

const isArrayOfEntities = (
  array: Array<any>,
  entityIdentifiers: Array<string>,
): boolean => {
  if (isEmpty(array)) {
    return false;
  }

  // eslint-disable-next-line no-unreachable-loop
  for (const item of array) {
    return some(entityIdentifiers, (identifier) =>
      hasOwnProperty(item, identifier),
    );
  }

  return false;
};

const cleanPatchOps = (
  ops: Array<PatchOp>,
  entityIdentifiers: Array<string>,
): Array<PatchOp> => {
  const cleanOps = ops.filter(
    (op) =>
      !some(
        entityIdentifiers,
        (identifier) =>
          op.path.includes(`/${identifier}`) && op.op === 'replace',
      ),
  );

  return cleanOps.map((op) => {
    if (isObject(op.value)) {
      const clone = deepClone(op);

      entityIdentifiers.forEach(
        (identifier) => delete clone.value[identifier as any],
      );

      return clone;
    }

    return op;
  });
};

const generateFastPatchOps = <T>(
  originalObject: T,
  updatedObject: T,
  res: Array<PatchOp> = [],
  path = '',
): void => {
  if (updatedObject === originalObject) {
    return;
  }

  if (isFunction(get(updatedObject, 'toJSON'))) {
    updatedObject = (updatedObject as any).toJSON();
  }

  const newKeys = keys(updatedObject) as Array<keyof T>;
  const oldKeys = keys(originalObject) as Array<keyof T>;

  let deleted = false;

  for (let t = oldKeys.length - 1; t >= 0; t--) {
    const key = oldKeys[t] as keyof T;
    const oldVal = originalObject[key];
    const isArray = Array.isArray(updatedObject);
    const hasKey = hasOwnProperty<T>(updatedObject, key as any);
    const completePath = `${path}/${escapePathComponent(key as string)}`;

    if (hasKey && !(isNil(updatedObject[key]) && !isNil(oldVal) && !isArray)) {
      const newVal = updatedObject[key];

      if (
        typeof oldVal === 'object' &&
        oldVal !== null &&
        typeof newVal === 'object' &&
        newVal !== null &&
        Array.isArray(oldVal) === Array.isArray(newVal)
      ) {
        // recursive 100
        generateFastPatchOps(oldVal, newVal, res, completePath);
      } else if (oldVal !== newVal) {
        res.push({
          op: 'replace',
          path: completePath,
          value: deepClone(newVal),
        });
      }
    } else if (Array.isArray(originalObject) === Array.isArray(updatedObject)) {
      res.push({
        op: 'remove',
        path: completePath,
      });

      deleted = true;
    } else {
      res.push({ op: 'replace', path, value: updatedObject });
    }
  }

  if (!deleted && newKeys.length === oldKeys.length) {
    return;
  }

  for (let t = 0; t < newKeys.length; t++) {
    const key = newKeys[t];

    if (
      !hasOwnProperty(originalObject, key as string) &&
      updatedObject[key] !== undefined
    ) {
      res.push({
        op: 'add',
        path: `${path}/${escapePathComponent(key as string)}`,
        value: deepClone(updatedObject[key]),
      });
    }
  }
};

const handlePrimitiveUpdate = (
  originalValue: any,
  updatedValue: any,
  path: string,
): PatchOp | undefined => {
  if (isNil(originalValue) && isNil(updatedValue)) {
    return undefined;
  }

  return new PatchOp(path, 'replace', updatedValue);
};

// TODO: Clean this up
const generatePartialItemPatchOps = <T>(
  originalItem: T,
  updatedItem: T,
  entityIdentifiers: Array<string>,
  path = '',
): Array<PatchOp> => {
  const res: Array<PatchOp> = [];

  const updatedItemKeys = keys(updatedItem) as Array<keyof T>;

  updatedItemKeys.forEach((updatedItemKey: keyof T) => {
    const completePath = `${path}/${escapePathComponent(
      updatedItemKey as string,
    )}`;

    const originalHadProperty = hasOwnProperty(
      originalItem,
      updatedItemKey as string,
    );

    const originalValue = originalItem[updatedItemKey];
    const updatedValue = updatedItem[updatedItemKey];

    if (originalHadProperty) {
      const valuesAreDifferent = originalValue !== updatedValue;

      if (valuesAreDifferent) {
        if (
          isPrimitive(updatedValue) ||
          (isPrimitive(originalValue) && !isPrimitive(updatedValue))
        ) {
          const patchOp = handlePrimitiveUpdate(
            originalValue,
            updatedValue,
            completePath,
          );

          if (patchOp) {
            res.push(patchOp);
          }
        } else if (
          Array.isArray(originalValue) &&
          Array.isArray(updatedValue) &&
          (isArrayOfEntities(originalValue, entityIdentifiers) ||
            isArrayOfEntities(updatedValue, entityIdentifiers))
        ) {
          const originalArray = originalValue;
          const updatedArray = updatedValue;
          const arrayWithEntities = originalArray.length
            ? originalArray
            : updatedArray;
          const identifier = find(entityIdentifiers, (identifier) =>
            hasOwnProperty(arrayWithEntities[0], identifier),
          );

          if (identifier) {
            originalArray.forEach((item, index) => {
              const matchingEntity = find(updatedArray, {
                [identifier]: item[identifier],
              });

              if (matchingEntity) {
                res.push(
                  ...generatePatchOps(
                    item,
                    matchingEntity,
                    true,
                    entityIdentifiers,
                    `${completePath}/${index}`,
                  ),
                );
              } else {
                res.push(new PatchOp(`${completePath}/${index}`, 'remove'));
              }
            });

            updatedArray.forEach((item) => {
              const matchingEntity = find(originalArray, {
                [identifier]: item[identifier],
              });

              if (!matchingEntity) {
                res.push(new PatchOp(`${completePath}/-`, 'add', item));
              }
            });
          }
        } else {
          res.push(
            ...generatePatchOps(
              originalValue,
              updatedValue,
              true,
              entityIdentifiers,
              completePath,
            ),
          );
        }
      }
    } else {
      res.push(new PatchOp(completePath, 'add', updatedValue));
    }
  });

  return res;
};

const generatePatchOps = <T>(
  originalItem: T,
  updatedItem: T,
  updatedItemIsPartial: boolean,
  entityIdentifiers: Array<string>,
  path = '',
): Array<PatchOp> => {
  const res: Array<PatchOp> = [];

  if (updatedItemIsPartial) {
    res.push(
      ...generatePartialItemPatchOps(
        originalItem,
        updatedItem,
        entityIdentifiers,
        path,
      ),
    );
  } else {
    const secondaryRes: Array<PatchOp> = [];

    generateFastPatchOps(originalItem, updatedItem, secondaryRes, path);

    res.push(...secondaryRes.reverse());
  }

  return cleanPatchOps(res, entityIdentifiers);
};

/**
 *
 * @param originalItem  the original entity that is being updated
 * @param updatedItem the updated entity
 * @param updatedItemIsPartial if this is set to true it's not required to provide the entire object as the updatedItem, however no remove patch ops will be generated
 * @param entityIdentifiers an array of identifier keys that uniquely identify an entity (id) by default
 * @returns an array of patch ops
 */
export const composePatchOps = <T>(
  originalItem: T,
  updatedItem: T,
  updatedItemIsPartial = true,
  entityIdentifiers: Array<string> = ['id'],
): Array<PatchOp> =>
  generatePatchOps(
    originalItem,
    updatedItem,
    updatedItemIsPartial,
    entityIdentifiers,
  );
