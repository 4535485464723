import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';

import { SeoService } from './seo.service';

export const seoResetGuard: CanDeactivateFn<boolean> = (): boolean => {
  const seoService = inject(SeoService);

  seoService.reset();

  return true;
};
