import { Injectable } from '@angular/core';
import { find } from 'lodash-es';
import { Observable, of } from 'rxjs';

import { ExternalScript } from '@aw/prypco/environment';

@Injectable({
  providedIn: 'root',
})
export class ScriptManagerService {
  private readonly scripts: Array<ExternalScript> = [];

  load(script: ExternalScript): Observable<ExternalScript | null> {
    const existingScript = find(this.scripts, { name: script.name });

    if (existingScript) {
      return of(existingScript);
    }
    if (!script.enabled) {
      return of(null);
    }
    return this.loadNewScript(script);
  }

  private loadNewScript(script: ExternalScript): Observable<ExternalScript> {
    this.scripts.push(script);

    const scriptElement = this.createScriptElement(script.src);

    return new Observable<ExternalScript>((observer) => {
      scriptElement.onload = () => {
        script.setLoaded(true);
        observer.next(script);
        observer.complete();
      };

      scriptElement.onerror = () => {
        observer.error(`Couldn't load script: ${script.src}`);
      };
    });
  }

  private readonly createScriptElement = (
    scriptSrc: string,
  ): HTMLScriptElement => {
    const scriptElement = document.createElement('script');
    scriptElement.type = 'text/javascript';
    scriptElement.src = scriptSrc;
    scriptElement.async = true;

    document.getElementsByTagName('body')[0].appendChild(scriptElement);

    return scriptElement;
  };
}
