import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { IconModule, SvgIconName } from '@aw/shared/components/icon';
import { NotificationType } from '@aw/shared/constants';
import { NOTIFICATION_TYPE } from '@aw/shared/enums';

import { RefDataPipe } from '@aw/prypco/pipes/reference-data';
import { ReferenceDataCatalogue } from '@aw/prypco/services/reference-data';

import { snackBarIcon } from '../../constants/snackbar-icon.const';
import { SnackBarRef } from '../../models/snackbar-ref.model';
import { SNACKBAR_REF } from '../../tokens/snackbar-ref.token';
import { SnackBarContainerComponent } from '../snackbar-container/snackbar-container.component';

@Component({
  standalone: true,
  selector: 'po-snackbar',
  templateUrl: 'snackbar.component.html',
  imports: [NgIf, RefDataPipe, NgClass, SnackBarContainerComponent, IconModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnackBarComponent {
  protected readonly NOTIFICATION_TYPE = NOTIFICATION_TYPE;

  protected readonly ReferenceDataCatalogue = ReferenceDataCatalogue;

  protected readonly ref = inject(SNACKBAR_REF) as SnackBarRef;

  protected readonly icon: SvgIconName;

  protected readonly type: NotificationType;

  constructor() {
    this.type = this.ref.config.type;
    this.icon = snackBarIcon[this.type];
  }

  onClose(): void {
    this.ref.close();
  }
}
