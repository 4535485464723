import { Money, uuid } from '@aw/shared/models';

import { BaseSearchPayload } from '@aw/prypco/models';

export enum BlocksMemberRewardType {
  Investment = 'INVESTMENT',
  Referral = 'REFERRAL',
  TopUp = 'ADD_FUNDS',
  Enrollment = 'ENROLL',
  PaymentWithPoints = 'PAYMENT_WITH_POINTS',
  Reversal = 'REVERSAL',
}

export enum BlocksMemberRewardSource {
  Blocks = 'BLOCKS',
}

export interface BlocksMemberRewards {
  transactionDate: string;
  transactionType: BlocksMemberRewardType;
  source: BlocksMemberRewardSource;
  amount: Money;
  points: number;
  internalReferenceId: uuid;
}

export interface BlocksMemberRewardsFilters
  extends BaseSearchPayload<BlocksMemberRewards> {}
