import { HttpClient } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { format } from 'date-fns';
import { findIndex, get, groupBy, keys, values } from 'lodash-es';
import { Observable, Subject, finalize, map, mergeMap, tap } from 'rxjs';

import { Money, SortDirection, uuid } from '@aw/shared/models';
import { BlobService } from '@aw/shared/services/blob';

import { Language } from '@aw/prypco/enums';
import { prypcoEnv } from '@aw/prypco/environment';

import { ReferenceDataCatalogue } from './enums/reference-data.enums';
import { ReferenceDataDictionary } from './models/entities/reference-data-dictionary.model';
import { ReferenceDataUnifiedCatalogue } from './models/entities/reference-data-unified-catalogue.model';
import { ReferenceData } from './models/entities/reference-data.model';

@Injectable({ providedIn: 'root' })
export class ReferenceDataService {
  // TODO: Logic to lazy load on the current language
  readonly currentLanguage = Language.English;

  readonly referenceData: Record<string, Record<string, string>>;

  readonly languages: Array<Language> = [Language.English];

  protected refDataDictionary: ReferenceDataDictionary;

  get currency(): string {
    return this.referenceData[ReferenceDataCatalogue.Essential]['CURRENCY'];
  }

  get blocksInvestmentLimitAmount(): Money {
    const currency =
      this.referenceData[ReferenceDataCatalogue.BlocksInvestment]['CURRENCY'];
    const amount = parseFloat(
      this.referenceData[ReferenceDataCatalogue.BlocksInvestment]['LIMIT'],
    );
    return new Money(currency, amount);
  }

  get blocksInvestmentPropertyLimitAmount(): Money {
    const currency =
      this.referenceData[ReferenceDataCatalogue.BlocksInvestment]['CURRENCY'];
    const amount = parseFloat(
      this.referenceData[ReferenceDataCatalogue.BlocksInvestment]['PROPERTY_LIMIT'],
    );
    return new Money(currency, amount);
  }

  get vat(): number {
    const value = this.referenceData[ReferenceDataCatalogue.Essential][
      'VAT'
    ] as string;

    return parseInt(value, 10);
  }

  constructor(
    protected readonly httpClient: HttpClient,
    @Optional() private readonly blobService?: BlobService,
  ) {
    this.baseUrl = prypcoEnv.endpoints.referenceData;
    this.referenceData = this.initProxy();
  }

  private readonly baseUrl: string;

  init(): Observable<void> {
    const loading$ = new Subject<void>();

    this.getReferenceData(...this.languages)
      .pipe(
        finalize(() => {
          loading$.next();
          loading$.complete();
        }),
      )
      .subscribe({
        next: (data) => {
          this.refDataDictionary = this.composeReferenceDataDictionary(data);
        },
      });

    return loading$.asObservable();
  }

  getCatalogue(
    catalogue: ReferenceDataCatalogue,
    options?: { sortDir: SortDirection },
  ): Array<ReferenceData> {
    const refData = get(
      this.refDataDictionary,
      [this.currentLanguage, catalogue],
      [],
    );

    let res = values(refData);

    if (options && options.sortDir) {
      const sorted = res.sort((a, b) => {
        const translatedA = this.referenceData[a.catalogueName][a.key];
        const translatedB = this.referenceData[b.catalogueName][b.key];

        return translatedA.localeCompare(translatedB);
      });

      if (options.sortDir === 'desc') {
        res = sorted.reverse();
      } else {
        res = sorted;
      }
    }

    return res;
  }

  // TODO: Clean up this monster
  getUnifiedCatalogue(
    catalogue: ReferenceDataCatalogue,
  ): Array<ReferenceDataUnifiedCatalogue> {
    const languages = keys(this.refDataDictionary) as Array<Language>;

    return languages.reduce(
      (acc: Array<ReferenceDataUnifiedCatalogue>, language: Language) => {
        const catalogues = this.refDataDictionary[language];
        const targetCatalogue = catalogues[catalogue];
        const refDataKeys = keys(targetCatalogue);

        refDataKeys.forEach((key) => {
          const existingEntryIndex = findIndex(acc, { key });

          if (existingEntryIndex !== -1) {
            acc.splice(existingEntryIndex, 1, {
              ...acc[existingEntryIndex],
              values: {
                ...acc[existingEntryIndex].values,
                [language]: targetCatalogue[key].value,
              },
            });
          } else {
            acc.push({
              id: targetCatalogue[key].id as uuid,
              key,
              catalogueName: catalogue,
              values: {
                [language]: targetCatalogue[key].value,
              },
            });
          }
        });

        return acc;
      },
      [],
    );
  }

  deleteReferenceData(id: uuid): Observable<void> {
    return this.httpClient
      .delete<void>(`${this.baseUrl}/${id}`)
      .pipe(mergeMap(() => this.init()));
  }

  export(): Observable<void> {
    return this.httpClient
      .get<Blob>(`${this.baseUrl}/Export`, { responseType: 'blob' as 'json' })
      .pipe(
        tap((blob) => {
          if (this.blobService) {
            const now = new Date();
            const formattedDate = format(now, 'dd_MM_yyy_HH:mm:ss');
            const fileName = `Reference_Data_Export_${formattedDate}.zip`;

            this.blobService.download(fileName, blob);
          }
        }),
        map(() => undefined),
      );
  }

  private initProxy(): Record<string, Record<string, string>> {
    return new Proxy(
      {},
      {
        get: (_, catalogueName: ReferenceDataCatalogue) =>
          new Proxy(
            {},
            {
              get: (__, referenceDataKey: string) => {
                const valueKey: keyof ReferenceData = 'value';

                if (
                  referenceDataKey === 'undefined' ||
                  referenceDataKey === 'null'
                ) {
                  return '';
                }

                return get(
                  this.refDataDictionary,
                  [
                    this.currentLanguage,
                    catalogueName,
                    referenceDataKey,
                    valueKey,
                  ],
                  referenceDataKey,
                );
              },
            },
          ),
      },
    );
  }

  private getReferenceData(
    ...languages: Array<Language>
  ): Observable<Record<Language, Array<ReferenceData>>> {
    return this.httpClient.get<Record<Language, Array<ReferenceData>>>(
      this.baseUrl,
      {
        params: { languages },
      },
    );
  }

  private readonly groupForLanguage = (
    language: Language,
    referenceData: Partial<Record<Language, Array<ReferenceData>>>,
  ): Record<ReferenceDataCatalogue, Record<string, ReferenceData>> => {
    const catalogueNameKey: keyof ReferenceData = 'catalogueName';
    const referenceDataIdentifier: keyof ReferenceData = 'key';

    const groupedByCatalogue = groupBy(
      referenceData[language],
      catalogueNameKey,
    );

    const catalogues = keys(
      groupedByCatalogue,
    ) as Array<ReferenceDataCatalogue>;

    return catalogues.reduce(
      (acc, catalogue) => {
        const groupedByKey = groupBy(
          groupedByCatalogue[catalogue],
          referenceDataIdentifier,
        );
        const refDataKeys = keys(groupedByKey) as Array<string>;

        acc[catalogue] = refDataKeys.reduce(
          (acc, key) => {
            // eslint-disable-next-line prefer-destructuring
            acc[key] = groupedByKey[key][0];

            return acc;
          },
          {} as Record<string, ReferenceData>,
        ) as Record<string, ReferenceData>;

        return acc;
      },
      {} as Record<ReferenceDataCatalogue, Record<string, ReferenceData>>,
    );
  };

  protected composeReferenceDataDictionary = (
    referenceData: Partial<Record<Language, Array<ReferenceData>>>,
  ): ReferenceDataDictionary => {
    const languages = keys(referenceData) as Array<Language>;

    return languages.reduce((acc, language) => {
      acc[language] = this.groupForLanguage(language, referenceData);

      return acc;
    }, {} as ReferenceDataDictionary);
  };
}
