export const isSafeNumber = (value: number) => !isNaN(value) && isFinite(value);

/**
 * X is what percentage of Y
 * @param x first number
 * @param y second number
 * @param decimals number of floating point decimals
 * @returns percentage value
 */

export const computePercentage = (
  x: number,
  y: number,
  decimals = 2,
): number => {
  const percentage = (x / y) * 100;
  const fixedPercentage = percentage.toFixed(decimals);
  const result = parseFloat(fixedPercentage);

  return isSafeNumber(result) ? result : 0;
};

/**
 * What is percentage of y
 * @param percentage the percentage to compute
 * @param y the number the percentage is computed from
 * @param decimals number of floating point decimals
 * @returns value of percentage
 */

export const computePercentageOf = (
  percentage: number,
  y: number,
  decimals = 2,
): number => {
  const value = (percentage / 100) * y;
  const fixedValue = value.toFixed(decimals);

  return parseFloat(fixedValue);
};

export const computePercentageDiff = (x: number, y: number): number =>
  parseFloat((((y - x) / x) * 100).toFixed(4));

export const roundTo2Decimals = (value: number): number =>
  Math.round((value + Number.EPSILON) * 100) / 100;
