import { Injectable } from '@angular/core';

import { BlobServiceBase } from './models/blob-service-base.model';

@Injectable({ providedIn: 'root' })
export class BlobService extends BlobServiceBase {
  download(fileName: string, blob: Blob): void {
    const url = window.URL.createObjectURL(blob);

    this.downloadFile(url, fileName);

    window.URL.revokeObjectURL(url);
  }

  downloadWithUrl(url: string, fileName?: string): void {
    this.downloadFile(url, fileName);
  }

  private readonly downloadFile = (url: string, fileName?: string): void => {
    const a = document.createElement('a') as HTMLAnchorElement;

    document.body.appendChild(a);

    a.style.display = 'none';
    a.href = url;

    if (fileName) {
      a.download = fileName;
    }

    a.click();

    document.body.removeChild(a);
  };
}
