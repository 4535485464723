import { Pipe, PipeTransform, inject } from '@angular/core';
import { isEmpty, isNil, keys } from 'lodash-es';

import {
  ReferenceDataCatalogue,
  ReferenceDataService,
} from '@aw/prypco/services/reference-data';

// TODO: Make impure and implement memoization
@Pipe({
  standalone: true,
  name: 'refData',
})
export class RefDataPipe implements PipeTransform {
  private readonly refDataService = inject(ReferenceDataService);

  transform(
    key: string | null | undefined,
    catalogue: ReferenceDataCatalogue | string | null | undefined,
    params?: Record<string, number | string | null>,
  ): string {
    if (isNil(key)) {
      return '';
    }

    if (isNil(catalogue)) {
      return key;
    }

    const translation = this.refDataService.referenceData[catalogue][key];

    if (!isNil(params) && !isEmpty(params)) {
      return this.replaceParams(translation, params);
    }

    return translation;
  }

  private readonly replaceParams = (
    translatedText: string,
    params: Record<string, string | number | null>,
  ): string => {
    const paramKeys = keys(params) as Array<string>;

    let res = translatedText;

    paramKeys.forEach((paramKey) => {
      const value = (
        isNil(params[paramKey]) ? '' : (params[paramKey] as any)
      ).toString();

      res = res.replace(`{{${paramKey}}}`, value);
    });

    return res;
  };
}
