export enum BlocksPortalPage {
  Settings = 'settings',
  PropertyListing = 'properties',
  Dashboard = 'dashboard',
  PropertyDetails = 'property-details',
  Rewards = 'rewards',
  HowItWorks = 'how-it-works',
  SignUp = 'signup',
  PrivacyPolicy = 'privacy-policy',
  PressRelease = 'press-release',
  TermsAndConditions = 'tnc',
  GuestCart = 'guest-cart',
  KeyRisks = 'key-risks',
  OurStory = 'our-story',
  Wallet = 'wallet',
  CookiePolicy = 'cookie-policy',
  PaymentSuccess = 'success',
  FailPayByCard = 'fail-card',
  ConfirmationPayByCard = 'confirmation-card',
  TopUp = 'top-up',
  ContactUs = 'contact',
  ConfirmationPayByDirectDebit = 'direct-debit',
  MyRewards = 'my-rewards',
}

export enum BlocksPortalModule {
  Checkout = 'checkout',
  Cart = 'cart',
  Wallet = 'wallet',
  Properties = 'properties',
  Profile = 'profile',
  Notifications = 'notifications',
  MyInvestments = 'my-investments',
}
