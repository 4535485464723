import { DOCUMENT, Location } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Route, Router, RouterStateSnapshot } from '@angular/router';
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import { get, isNil } from 'lodash-es';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export class AuthGuard extends MsalGuard {
  constructor(
    @Inject(MSAL_GUARD_CONFIG)
    override readonly msalGuardConfig: MsalGuardConfiguration,
    override readonly msalBroadcastService: MsalBroadcastService,
    override readonly authService: MsalService,
    override readonly location: Location,
    override readonly router: Router,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    super(msalGuardConfig, msalBroadcastService, authService, location, router);
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  override canLoad(route: Route): Observable<boolean> {
    const routePath = get(route, ['data', 'path'], undefined);

    const currentHref = this.document.location.href;
    const { origin } = this.document.location;

    const fallbackUrl = currentHref.replace(origin, '');

    const url = isNil(routePath) ? fallbackUrl : routePath;

    const state: Partial<RouterStateSnapshot> = {
      url,
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.activateHelper(state);
  }
}
