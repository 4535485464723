import { Location } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { isEmpty, last, pick, values } from 'lodash-es';

import { LocalStorageService } from '@aw/shared/services/local-storage';

import { UTM_TAG } from './constants/utm.const';
import { UtmTag } from './types/utm.types';

@Injectable({ providedIn: 'root' })
export class UtmService {
  private readonly location = inject(Location);

  private readonly localStorage = inject(LocalStorageService);

  private readonly storageKey = 'utm';

  init(): void {
    const utmTags = this.getUtmTags();

    if (!isEmpty(utmTags)) {
      utmTags.platform = window.navigator.userAgent;

      this.localStorage.set(this.storageKey, utmTags);
    }
  }

  getTags(): Partial<Record<UtmTag, string>> | undefined {
    return this.localStorage.get(this.storageKey);
  }

  deleteTags(): void {
    this.localStorage.remove(this.storageKey);
  }

  private getUtmTags(): Partial<Record<UtmTag, string>> {
    const queryParams = this.getQueryParams();
    const tags = pick(queryParams, values(UTM_TAG));

    return tags;
  }

  private getQueryParams(): Record<string, string> {
    const res: Record<string, string> = {};

    const path = last((this.location.path() || '').split('?'));

    const params = new URLSearchParams(path);

    params.forEach((value, key) => {
      res[key] = value;
    });

    return res;
  }
}
