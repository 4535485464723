import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { EventFacade } from './event.facade';
import * as fromEvent from './event.reducer';

@NgModule({
  imports: [StoreModule.forFeature(fromEvent.featureKey, fromEvent.reducer)],
  providers: [EventFacade],
})
export class EventStateModule {}
