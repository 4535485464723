import { HttpEventType, HttpProgressEvent } from '@angular/common/http';

export class UploadProgressEvent {
  readonly loaded: number;

  readonly total: number;

  readonly timestamp: Date;

  readonly type: HttpEventType;

  constructor(readonly event: HttpProgressEvent) {
    this.type = event.type;
    this.timestamp = (event as any).timestamp || new Date();
    this.loaded = this.event.loaded;
    this.total = this.event.total || 1;
  }
}

export interface DocumentUploadProgressEvent<T> {
  id: string;
  uploadSpeed: number;
  uploadProgress: number;
  finalEntity?: T;
  error?: Text;
}
