import { Injectable, OnDestroy } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { isNil } from 'lodash-es';
import { Observable, Subject, distinctUntilChanged, filter } from 'rxjs';

import { GlobalEventType } from './enum/events.enum';
import * as EventActions from './event.actions';
import * as EventSelectors from './event.selectors';
import { EventFacadeBase } from './models/event-facade-base.model';
import { GlobalEvent } from './models/global-event.model';

@UntilDestroy()
@Injectable()
export class EventFacade implements EventFacadeBase, OnDestroy {
  readonly event$: Observable<GlobalEvent>;

  constructor(private readonly store: Store) {
    this.event$ = this._event$.asObservable();

    this.initEventListener();
  }

  private readonly _event$ = new Subject<GlobalEvent>();

  dispatch(event: GlobalEventType, payload?: any): void {
    this.store.dispatch(EventActions.dispatch({ event, payload }));
  }

  ngOnDestroy(): void {
    this._event$.complete();
  }

  private initEventListener(): void {
    this.store
      .select(EventSelectors.getEvent)
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged(),
        filter((event) => !isNil(event)),
      )
      .subscribe({ next: (event) => this._event$.next(event as GlobalEvent) });
  }
}
