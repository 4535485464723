import {
  CanActivate,
  CanActivateFn,
  CanDeactivate,
  CanDeactivateFn,
  CanLoad,
  CanLoadFn,
  Route,
} from '@angular/router';
import { get } from 'lodash-es';

import { SeoData } from './models/seo-data.model';
import { seoResetGuard } from './seo-reset.guard';
import { seoGuard } from './seo.guard';

export const composeSeoRoute = (route: Route, seo: SeoData): Route => {
  const canLoadGuards = get(route, 'canLoad', []) as Array<CanLoad | CanLoadFn>;
  const canActivateGuards = get(route, 'canActivate', []) as Array<
    CanActivate | CanActivateFn
  >;
  const canDeactivateGuards = get(route, 'canDeactivate', []) as Array<
    CanDeactivate<boolean> | CanDeactivateFn<boolean>
  >;
  const guards = get(route, ['data', 'guards'], []);

  if (canLoadGuards.length && guards.length) {
    // The SequenceGuard is present
    guards.push(seoGuard);
  } else {
    canActivateGuards.push(seoGuard);
  }

  canDeactivateGuards.push(seoResetGuard);

  return {
    ...route,
    canActivate: canActivateGuards,
    canDeactivate: canDeactivateGuards,
    canLoad: canLoadGuards,
    data: { ...route.data, guards, seo },
  };
};
