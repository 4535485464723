import { cloneDeep } from 'lodash-es';

import { BusinessUnit } from '@aw/prypco/enums';
import { AccountState, AccountStates } from '@aw/prypco/services/user';

import { State } from './auth.reducer';

export function setAccountState(
  state: State,
  businessUnit: BusinessUnit,
  accountState: AccountState,
  value: boolean,
) {
  const newAccountStates: Record<BusinessUnit, AccountStates> =
    cloneDeep(state.accountStates) || ({} as any);

  if (newAccountStates[businessUnit]) {
    newAccountStates[businessUnit][accountState] = value;
  } else {
    newAccountStates[businessUnit] = {
      [accountState]: value,
    };
  }
  return newAccountStates;
}
