import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { find as _find, get, isNil, noop } from 'lodash-es';
import { Observable, combineLatest, filter, map, withLatestFrom } from 'rxjs';

import { NavSegments, uuid } from '@aw/shared/models';

import { BusinessUnit, JobTitle, Module, Page } from '@aw/prypco/enums';
import {
  AccountState,
  AccountStates,
  ExternalIdentifier,
  UserIdentifier,
} from '@aw/prypco/services/user';

import * as AuthActions from './auth.actions';
import * as AuthSelectors from './auth.selectors';
import { AuthFacadeBase } from './models/auth-base-facade.model';
import { AuthToken } from './models/entities/auth-token.model';
import { LeadIdRecord } from './models/entities/lead-id-record.model';

// TODO: TEST
@Injectable()
export class AuthFacade implements AuthFacadeBase {
  readonly authenticated$: Observable<boolean> = this.store
    .select(AuthSelectors.selectId)
    .pipe(
      withLatestFrom(this.store.select(AuthSelectors.selectInitialized)),
      map(([userId, isInitialized]) => isInitialized && !isNil(userId)),
    );

  readonly unAuthenticated$ = this.authenticated$.pipe(
    map((authenticated) => !authenticated),
  );

  readonly id$: Observable<uuid> = this.store
    .select(AuthSelectors.selectId)
    .pipe(filter((id) => !isNil(id))) as Observable<uuid>;

  readonly firstName$: Observable<string> = this.store.select(
    AuthSelectors.getFirstName,
  );

  readonly lastName$: Observable<string> = this.store.select(
    AuthSelectors.getLastName,
  );

  readonly jobTitle$: Observable<JobTitle> = this.store
    .select(AuthSelectors.getJobTitle)
    .pipe(
      withLatestFrom(this.store.select(AuthSelectors.selectInitialized)),
      filter(([_, isInitialized]) => {
        noop(_);
        return isInitialized;
      }),
      map(([jobTitle]) => jobTitle),
    );

  readonly fullName$: Observable<string> = this.store.select(
    AuthSelectors.getFullName,
  );

  readonly customerId$: Observable<uuid> = this.store.select(
    AuthSelectors.getCustomerId,
  );

  readonly initials$: Observable<string> = combineLatest([
    this.firstName$,
    this.lastName$,
  ]).pipe(
    map(([firstName, lastName]) => this.composeInitials(firstName, lastName)),
  );

  readonly initialized$: Observable<boolean> = this.store.select(
    AuthSelectors.selectInitialized,
  );

  readonly user$: Observable<AuthToken> = this.store
    .select(AuthSelectors.getUser)
    .pipe(
      withLatestFrom(this.store.select(AuthSelectors.selectInitialized)),
      filter(([_, isInitialized]) => {
        noop(_);
        return isInitialized;
      }),
      map(([user]) => user),
    );

  readonly canCall$ = this.getExternalIdentifier(UserIdentifier.ZiwoId).pipe(
    map((ziwoId) => !isNil(ziwoId)),
  );

  constructor(private readonly store: Store) {}

  private initCalled = false;

  private readonly accountStates$: Observable<AccountStates> = this.store
    .select(AuthSelectors.selectAccountStates)
    .pipe(
      withLatestFrom(
        this.store.select(AuthSelectors.selectInitialized),
        this.store.select(AuthSelectors.selectLoading),
      ),
      filter(([_, isInitialized, isLoading]) => isInitialized && !isLoading),
      map(([accountStates]) => accountStates),
    ) as Observable<AccountStates>;

  private readonly leadIds$: Observable<LeadIdRecord> = this.store
    .select(AuthSelectors.selectLeadIdRecord)
    .pipe(
      withLatestFrom(this.store.select(AuthSelectors.selectInitialized)),
      filter(
        ([leadIdRecord, isInitialized]) =>
          isInitialized && !isNil(leadIdRecord),
      ),
      map(([leadIdRecord]) => leadIdRecord),
    ) as Observable<LeadIdRecord>;

  init(): void {
    if (this.initCalled) {
      console.warn('AUTH STATE SHOULD ONLY BE INITIALIZED ONCE!');
    } else {
      this.initCalled = true;
      this.store.dispatch(AuthActions.init());
    }
  }

  resetPassword(): void {
    this.store.dispatch(AuthActions.resetPassword());
  }

  getLeadId(businessUnit: BusinessUnit): Observable<uuid | null> {
    return this.leadIds$.pipe(
      map((leadIdRecord) => get(leadIdRecord, [businessUnit], null)),
    );
  }

  getAccountState(
    businessUnit: BusinessUnit,
    accountState: AccountState,
    coerceToFalse = true,
  ): Observable<boolean> {
    return this.accountStates$.pipe(
      map((accountStates) =>
        get(
          accountStates,
          [businessUnit, accountState],
          coerceToFalse ? false : undefined,
        ),
      ),
    );
  }

  signUp(redirectUri: Array<string>): void {
    this.store.dispatch(AuthActions.signUp({ redirectUri }));
  }

  signUpSalesAgent(): void {
    this.store.dispatch(
      AuthActions.signUpSalesAgent({
        redirectUri: [Module.BackOffice, Page.SignUpSuccess],
      }),
    );
  }

  signUpMortgageCustomer(): void {
    this.store.dispatch(
      AuthActions.signUp({
        redirectUri: [Module.Mortgage, Module.Onboarding, Page.SignUpSuccess],
      }),
    );
  }

  logOut(businessUnit?: BusinessUnit, redirectUri?: NavSegments): void {
    this.store.dispatch(AuthActions.logOut({ businessUnit, redirectUri }));
  }

  signIn(redirectUri: NavSegments): void {
    this.store.dispatch(AuthActions.signIn({ redirectUri }));
  }

  setLeadId(leadId: uuid, businessUnit: BusinessUnit): void {
    this.store.dispatch(AuthActions.setLeadId({ leadId, businessUnit }));
  }

  getExternalIdentifier(
    key: UserIdentifier,
  ): Observable<ExternalIdentifier | undefined> {
    return this.store.select(AuthSelectors.selectExternalIdentifiers).pipe(
      withLatestFrom(this.store.select(AuthSelectors.selectInitialized)),
      filter(([_, isInitialized]) => {
        noop(_);
        return isInitialized;
      }),
      map(([externalIdentifiers]) =>
        _find(externalIdentifiers, { identifierKey: key }),
      ),
    );
  }

  refreshAccountStates(): void {
    this.store.dispatch(AuthActions.refreshAccountStates());
  }

  setAccountState(
    businessUnit: BusinessUnit,
    accountState: AccountState,
    value: boolean,
  ) {
    this.store.dispatch(
      AuthActions.setAccountState({ businessUnit, accountState, value }),
    );
  }

  private composeInitials(firstName: string, lastName?: string): string {
    const firstNameInitial = firstName[0].toUpperCase();

    if (firstName && lastName) {
      const lastNameInitial = lastName[0].toUpperCase();

      return `${firstNameInitial}${lastNameInitial}`;
    }

    return firstNameInitial;
  }
}
