export const UTM_TAG = {
  Platform: 'platform',
  Source: 'utm_source',
  Medium: 'utm_medium',
  Campaign: 'utm_campaign',
  Id: 'utm_id',
  Term: 'utm_term',
  Content: 'utm_content',
  Referral: 'referral',
} as const;
