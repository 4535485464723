import { Component } from '@angular/core';

import { IconModule } from '@aw/shared/components/icon';

@Component({
  standalone: true,
  selector: 'po-dialog-close-button',
  templateUrl: 'dialog-close-button.component.html',
  imports: [IconModule],
})
export class DialogCloseButtonComponent {}
