import { BehaviorSubject, Observable } from 'rxjs';

const REQUEST_STATUS = {
  New: 'new',
  Pending: 'pending',
  Complete: 'complete',
  Error: 'error',
} as const;

export type _RequestStatus =
  (typeof REQUEST_STATUS)[keyof typeof REQUEST_STATUS];

export interface DomainEntity<T> {
  domain: T | null;
  status: _RequestStatus;
  error?: any;
}

export type _DomainEntity<T> = Observable<DomainEntity<T>>;

export const loadDomainEntity = <T>(
  apiCall: Observable<T>,
): Observable<DomainEntity<T>> => {
  const subject = new BehaviorSubject<DomainEntity<T>>({
    domain: null,
    status: 'pending',
    error: undefined,
  });

  apiCall.subscribe({
    next: (value) => {
      subject.next({
        domain: value,
        status: 'complete',
      });
    },
    error: (error) => {
      subject.next({
        domain: null,
        status: 'error',
        error,
      });

      subject.complete();
    },
    complete: () => subject.complete(),
  });

  return subject.asObservable();
};

export const isDomainLoading = (domain: {
  status: _RequestStatus;
  [key: string]: any;
}): boolean => domain.status === 'new' || domain.status === 'pending';
