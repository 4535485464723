import { createReducer, on } from '@ngrx/store';

import * as SocketActions from './socket.actions';

export const featureKey = 'socket';

export interface SocketPartialState {
  readonly [featureKey]: State;
}

export interface State {
  isConnected: boolean;
  isConnecting: boolean;
  failedToConnect: boolean;
  messages: Array<any>;

  userIdentifier?: string;
}

export const initialState: State = {
  isConnected: false,
  isConnecting: false,
  failedToConnect: false,
  messages: [],

  userIdentifier: undefined,
};

export const reducer = createReducer(
  initialState,
  on(SocketActions.connect, (state) => ({
    ...state,
    isConnected: false,
    isConnecting: true,
    failedToConnect: false,
  })),
  on(SocketActions.connected, (state, { userIdentifier }) => ({
    ...state,
    userIdentifier,
    isConnected: true,
    isConnecting: false,
    failedToConnect: false,
  })),
  on(SocketActions.connectionFailure, (state) => ({
    ...state,
    isConnected: false,
    isConnecting: false,
    failedToConnect: true,
  })),
);
