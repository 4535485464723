import { JobTitle } from '@aw/prypco/enums';

export const maxFileUploadSize = 20_000_000; // 20MB

export const BUSINESS_UNIT = {
  Corporate: 'corporate',
  Mortgage: 'mortgage',
  Blocks: 'blocks',
  Services: 'services',
  Listing: 'listing',
};

export const blocksBackOfficeRoles: Array<JobTitle> = [
  JobTitle.BlocksComplianceOfficer,
  JobTitle.BlocksSysAdmin,
  JobTitle.BlocksOperationsAgent,
  JobTitle.BlocksFinanceAgent,
  JobTitle.BlocksTelecaller,
  JobTitle.BlocksRelationshipManager,
];

export const mortgageBackOfficeRoles: Array<JobTitle> = [
  JobTitle.SysAdmin,
  JobTitle.SuperMortgageAdvisorManager,
  JobTitle.MortgageAdvisorManagerAgency,
  JobTitle.MortgageAdvisorManagerBroker,
  JobTitle.MortgageAdvisor,
  JobTitle.MortgageAdvisorAgency,
  JobTitle.MortgageAdvisorBroker,
  JobTitle.MortgageBroker,
  JobTitle.SalesAgent,
  JobTitle.SalesAgencyManager,
  JobTitle.Telesales,
];

export const servicesBackOfficeRoles: Array<JobTitle> = [
  JobTitle.SysAdmin,
  JobTitle.SalesAgencyManager,
  JobTitle.SalesAgent,
  JobTitle.MortgageAdvisor,
  JobTitle.MortgageAdvisorAgency,
  JobTitle.MortgageAdvisorBroker,
];

export const listingBackOfficeRoles: Array<JobTitle> = [JobTitle.SysAdmin];

export const redirectBusinessUnitStorageKey = 'prypco-redirect-business-unit';
