import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil } from 'lodash-es';

import { SVG_ICON_NAMES } from './constants/icons';
import { IconService } from './services/icon.service';
import { SvgIconName } from './types/types';

@UntilDestroy()
@Component({
  selector: 'aw-icon',
  templateUrl: 'icon.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'aw-icon',
  },
})
export class IconComponent {
  // TODO: Remove
  @HostBinding('class.overflow-hidden')
  readonly noOverflow = true;

  @HostBinding('attr.aria-hidden')
  readonly ariaHidden = 'true';

  @Input({ required: true })
  set icon(_icon: SvgIconName) {
    this._icon = _icon;

    if (!isNil(_icon) && SVG_ICON_NAMES.includes(_icon)) {
      this.onIconChange(_icon);
    }
  }

  get icon(): SvgIconName {
    return this._icon;
  }

  @Input() fill: string;

  private readonly elementRef = inject(ElementRef) as ElementRef<HTMLElement>;

  private readonly iconService = inject(IconService);

  private _icon: SvgIconName = '';

  private onIconChange(iconName: SvgIconName): void {
    this.iconService
      .getSvgIcon(iconName)
      .pipe(untilDestroyed(this))
      .subscribe((icon) => {
        if (this.fill) {
          icon.setAttribute('fill', this.fill);
        }

        this.setSvg(icon);
      });
  }

  private setSvg(svg: SVGElement): void {
    const host = this.elementRef.nativeElement;

    if (host.firstChild) {
      host.replaceChild(svg, host.firstChild);
    }

    host.appendChild(svg);
  }
}
