import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { get, isNil } from 'lodash-es';

import { ENV, Environment } from '@aw/prypco/environment';

import { SeoData } from './models/seo-data.model';
import { SeoService } from './seo.service';

export const seoGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
): boolean => {
  const seoService = inject(SeoService);
  const env = inject(ENV) as Environment;

  const seo = get(route, ['data', 'seo'], undefined) as SeoData | undefined;

  if (!isNil(seo)) {
    const { title, description } = seo;

    seoService.setTitle(title);
    seoService.setDescription(description);
  } else if (env.dev) {
    console.warn(
      '[SEO Guard] Usage without data detected! Please provide the appropriate SeoData as part of the route data under the "seo" key',
    );
  }

  return true;
};
