import { Inject, Injectable, inject } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { isNil } from 'lodash-es';
import {
  Observable,
  combineLatest,
  first,
  map,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';

import { NavSegments } from '@aw/shared/models';
import { LocalStorageService } from '@aw/shared/services/local-storage';

import { redirectBusinessUnitStorageKey } from '@aw/prypco/constants';
import { BusinessUnit, JobTitle, Module, QueryParam } from '@aw/prypco/enums';
import { ENV, Environment } from '@aw/prypco/environment';
import { BlocksModule } from '@aw/prypco/modules/blocks/enum';
import { BlocksPortalModule } from '@aw/prypco/modules/blocks/modules/portal/enum';
import { PropertyStatus } from '@aw/prypco/modules/blocks/services/blocks';
import { AmplitudeService, GtmService } from '@aw/prypco/services/script-manager';
import { AccountState } from '@aw/prypco/services/user';
import { AuthFacade } from '@aw/prypco/state/auth';
import {
  isBlocksBackOfficeUser,
  isMortgageBackOfficeUser,
} from '@aw/prypco/utils';

@Injectable({ providedIn: 'root' })
export class AuthRedirectGuard implements CanActivate {
  constructor(
    private readonly authFacade: AuthFacade,
    private readonly router: Router,
    private readonly amplitudeService: AmplitudeService,
    private readonly gtmService: GtmService,
    @Inject(ENV) private readonly env: Environment,
  ) {
    this.isMortgageUser$ = combineLatest([
      this.authFacade.getLeadId(BusinessUnit.Mortgage),
      this.authFacade.jobTitle$,
    ]).pipe(
      first(),
      map(
        ([mortgageLeadId, jobTitle]) =>
          !isNil(mortgageLeadId) && jobTitle === JobTitle.Member,
      ),
    );

    this.isBlocksUserWithNoKyc$ = combineLatest([
      this.authFacade.getLeadId(BusinessUnit.Blocks),
      this.authFacade.getAccountState(
        BusinessUnit.Blocks,
        AccountState.Investor,
      ),
      this.authFacade.jobTitle$,
    ]).pipe(
      first(),
      map(
        ([blocksLeadId, isInvestor, jobTitle]) =>
          jobTitle === JobTitle.Member && !isNil(blocksLeadId) && !isInvestor,
      ),
    );

    this.isBlocksInvestor$ = combineLatest([
      this.authFacade.jobTitle$,
      this.authFacade.getAccountState(
        BusinessUnit.Blocks,
        AccountState.Investor,
      ),
    ]).pipe(
      first(),
      map(([jobTitle, investor]) => jobTitle === JobTitle.Member && investor),
    );

    this.isBlocksBackOfficeUser$ = this.authFacade.jobTitle$.pipe(
      first(),
      map((jobTitle) => isBlocksBackOfficeUser(jobTitle)),
    );

    this.isMortgageBackOfficeUser$ = this.authFacade.jobTitle$.pipe(
      first(),
      map((jobTitle) => isMortgageBackOfficeUser(jobTitle)),
    );
  }

  private readonly isMortgageUser$: Observable<boolean>;

  private readonly isBlocksInvestor$: Observable<boolean>;

  private readonly isBlocksUserWithNoKyc$: Observable<boolean>;

  private readonly isBlocksBackOfficeUser$: Observable<boolean>;

  private readonly isMortgageBackOfficeUser$: Observable<boolean>;

  private readonly localStorageService = inject(LocalStorageService);

  canActivate(): Observable<boolean> {
    return this.authFacade.authenticated$.pipe(
      take(1),
      switchMap((authenticated) => {
        const redirectBusinessUnit = this.localStorageService.get(
          redirectBusinessUnitStorageKey,
        ) as BusinessUnit;

        if (authenticated) {
          return this.handleAuthenticatedRedirect(
            redirectBusinessUnit || BusinessUnit.Corporate,
          );
        }

        return this.handleUnAuthenticatedRedirect(
          redirectBusinessUnit || BusinessUnit.Corporate,
        );
      }),
      tap(() =>
        this.localStorageService.remove(redirectBusinessUnitStorageKey),
      ),
      map(() => false),
    );
  }

  private handleUnAuthenticatedRedirect(
    redirectBusinessUnit: BusinessUnit,
  ): Observable<boolean> {
    let redirectSegments: NavSegments;

    switch (redirectBusinessUnit) {
      case BusinessUnit.Blocks:
        redirectSegments = [`/${Module.Blocks}`];
        break;
      case BusinessUnit.Mortgage:
        redirectSegments = [`/${Module.Mortgage}`];
        break;
      case BusinessUnit.Services:
        redirectSegments = [`/${Module.Services}`];
        break;
      default:
        redirectSegments = ['/'];
        break;
    }

    void this.router.navigate(redirectSegments);

    return of(false);
  }

  private handleAuthenticatedRedirect(
    redirectBusinessUnit: BusinessUnit,
  ): Observable<boolean> {
    return combineLatest([
      this.authFacade.getLeadId(BusinessUnit.Mortgage),
      this.authFacade.getLeadId(BusinessUnit.Blocks),
      this.authFacade.jobTitle$,
      this.isBlocksInvestor$,
      this.isBlocksUserWithNoKyc$,
      this.isMortgageUser$,
      this.isBlocksBackOfficeUser$,
      this.isMortgageBackOfficeUser$,
    ]).pipe(
      first(),
      map(
        ([
          morLeadId,
          blocksLeadId,
          jobTitle,
          isBlocksInvestor,
          isBlocksUserWithNoKyc,
          isMortgageUser,
          isBlocksBackOfficeUser,
          isMortgageBackOfficeUser,
        ]) => {
          if (isMortgageBackOfficeUser) {
            this.logRedirect(
              `Redirecting ${jobTitle} to the mortgage backoffice dashboard`,
            );

            return this.router.navigate([
              `/${Module.Mortgage}`,
              BlocksModule.BackOffice,
            ]);
          }
          if (isBlocksBackOfficeUser) {
            this.logRedirect(
              `Redirecting ${jobTitle} to the blocks backoffice dashboard`,
            );

            return this.router.navigate([
              `/${Module.Blocks}`,
              BlocksModule.BackOffice,
            ]);
          }

          if (redirectBusinessUnit === BusinessUnit.Mortgage) {
            if (isMortgageUser) {
              if (morLeadId) {
                this.amplitudeService.userId = morLeadId;

                this.amplitudeService.setUserProperty([
                  { eventProperty: 'lead id', eventValue: morLeadId },
                  { eventProperty: 'product interest', eventValue: 'MORTGAGE' },
                ]);
              }

              this.logRedirect(
                `Redirecting ${jobTitle} with mortgage lead to mortgage user dashboard`,
              );

              return this.router.navigate([
                `/${Module.Mortgage}`,
                Module.Dashboard,
              ]);
            }
          }

          if (redirectBusinessUnit === BusinessUnit.Blocks) {
            if (isBlocksInvestor) {
              if (blocksLeadId) {
                this.amplitudeService.userId = blocksLeadId;

                this.amplitudeService.setUserProperty([
                  { eventProperty: 'lead id', eventValue: blocksLeadId },
                  { eventProperty: 'product interest', eventValue: 'BLOCKS' },
                ]);

                this.gtmService.pushEvent({
                  event: 'user_login',
                  user_id: blocksLeadId,
                  timestamp: new Date().toISOString(),
                });
              }
            
              this.logRedirect(
                `Redirecting ${jobTitle} with blocks investor state to the properties listing page`,
              );

              return this.router.navigate(
                [`/${Module.Blocks}`, BlocksPortalModule.Properties],
                {
                  queryParams: {
                    [QueryParam.Status]: PropertyStatus.Available,
                  },
                },
              );
            }
            if (isBlocksUserWithNoKyc) {
              this.logRedirect(
                `Redirecting ${jobTitle} with incomplete blocks KYC to the blocks member dashboard`,
              );

              this.gtmService.pushEvent({
                event: 'user_login',
                user_id: blocksLeadId,
                timestamp: new Date().toISOString(),
              });

              return this.router.navigate([
                `/${Module.Blocks}`,
                Module.Dashboard,
              ]);
            }
            if (isMortgageUser) {
              if (morLeadId) {
                this.amplitudeService.userId = morLeadId;

                this.amplitudeService.setUserProperty([
                  { eventProperty: 'lead id', eventValue: morLeadId },
                  { eventProperty: 'product interest', eventValue: 'MORTGAGE' },
                ]);
              }

              this.logRedirect(`Redirecting ${jobTitle} to the blocks page`);

              return this.router.navigate([`/${Module.Blocks}`]);
            }
          }

          if (redirectBusinessUnit === BusinessUnit.Services) {
            return this.router.navigate([`/${Module.Services}`]);
          }

          if (this.env.dev) {
            console.warn(
              `The ${jobTitle} is not accounted for in the AuthRedirectGuard logic. Bailing to the corporate landing page`,
            );
          }

          return this.router.navigate(['/']);
        },
      ),
      map(() => false),
    );
  }

  private logRedirect(message: string): void {
    if (this.env.dev) {
      console.info(`[AuthRedirectGuard] ${message}`);
    }
  }
}
